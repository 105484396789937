import ReactGA from "react-ga";
import { Stack } from "@mui/system";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const Simple = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    hotjar.stateChange(location?.pathname);
  }, [location?.pathname]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location?.pathname, location?.search]);

  return (
    <Stack
      minHeight="100vh"
      height={["auto", "100vh"]}
      backgroundColor="primary.main"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Stack>
  );
};
