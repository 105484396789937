export const colorsKey1 = {
  "#9C27B0": [81, 100],
  "#7CB342": [61, 80],
  "#FEC722": [51, 60],
  "#FE6422": [41, 50],
  "#E65100": [0, 40],
};

export const colorsKey2 = {
  "#9C27B0": [81, 100],
  "#7CB342": [65, 80],
  "#FEC722": [41, 64],
  "#FE6422": [31, 40],
  "#E65100": [0, 30],
};

export const color = (score, colors = colorsKey1) =>
  Object.keys(colors).find(
    (key) => score >= colors[key][0] && score <= colors[key][1]
  );
