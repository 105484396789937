import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  resetPassword,
  signIn,
  signOut,
  signUp,
} from "aws-amplify/auth";

import { authTemp } from "../store";
import { createUserLogin } from "../airtable";
import { useAtom } from "jotai";
import { useIntercom } from "react-use-intercom";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const navigate = useNavigate();
  const { shutdown } = useIntercom();

  const [temp, setTemp] = useAtom(authTemp);

  const handleCheckAuth = async ({ bypassCache = false }) => {
    try {
      const { tokens } = await fetchAuthSession({ bypassCache });

      if (tokens?.accessToken) {
        return { ok: true };
      }

      return { ok: false };
    } catch (error) {
      return { ok: false, error };
    }
  };

  const handleLogin = async (email, password) => {
    try {
      const {
        nextStep: { signInStep },
        isSignedIn,
      } = await signIn({
        username: email,
        password,
      });

      createUserLogin({
        fields: {
          Email: email,
          "Sign In": new Date().toUTCString(),
          Environment: process.env.REACT_APP_ENVIRONMENT,
        },
      });

      if (isSignedIn) {
        return { ok: true };
      }

      if (signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        setTemp({ email, password, signInStep });
        navigate("/reset/password");
      } else {
        return { ok: true, signInStep };
      }
    } catch (error) {
      throw Error("There was an error logging you in. Please try again.");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      await shutdown();

      return { ok: true };
    } catch (error) {
      throw Error(error);
    }
  };

  const handleForgotPassword = async (email) => {
    try {
      await resetPassword({ username: email });

      return { status: "success" };
    } catch (error) {
      throw Error(error);
    }
  };

  const handleCompleteNewPassword = async (password) => {
    try {
      await signIn({
        username: temp.email,
        password: temp.password,
      });
      await confirmSignIn({ challengeResponse: password });

      return { ok: true };
    } catch (error) {
      throw Error(error);
    }
  };

  const handleForgotPasswordReset = async (email, password, code) => {
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: password,
      });
      return { status: "success" };
    } catch (error) {
      throw Error(error);
    }
  };

  const handleRegistration = async (email, password) => {
    try {
      const { isSignUpComplete } = await signUp({
        username: email,
        password,
        options: {
          autoSignIn: true,
        },
      });

      return { status: isSignUpComplete ? "success" : "error" };
    } catch (error) {
      throw Error(error);
    }
  };

  return {
    handleCheckAuth,
    handleCompleteNewPassword,
    handleRegistration,
    handleLogin,
    handleLogout,
    handleForgotPassword,
    handleForgotPasswordReset,
  };
};
