import { Box, Button, Modal, Paper, Stack } from "@mui/material";

import { PaymentModalForm } from "./PaymentModalForm";
import { paymentModal } from "../../../services/store";
import { useAtom } from "jotai";
import { useCallback } from "react";

export const PaymentModal = () => {
  const [open, setOpen] = useAtom(paymentModal);

  const handleClose = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <Box
          maxWidth={1200}
          width="90%"
          position="relative"
          sx={{ maxHeight: [650, "100vh"], overflow: "auto" }}
        >
          <Paper elevation={4}>
            <Stack
              paddingX={4}
              paddingTop={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button onClick={handleClose}>Close</Button>
            </Stack>
            <Box padding={4}>
              <PaymentModalForm />
            </Box>
          </Paper>
        </Box>
      </Stack>
    </Modal>
  );
};
