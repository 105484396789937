import { Box, FormHelperText, MenuItem, Select } from "@mui/material";

import { Controller } from "react-hook-form";

export const SelectField = ({
  name,
  label,
  helperText,
  placeholder,
  control,
  rules,
  size = "medium",
  options = [],
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Box>
          <Select
            {...field}
            label={label}
            placeholder={placeholder}
            sx={{ textTransform: "capitalize" }}
            size={size}
            fullWidth
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ textTransform: "capitalize" }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
      )}
    />
  );
};
