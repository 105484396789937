import { Button, Stack, Typography } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

export const DeBriefEmpty = ({ setItem }) => (
  <Stack
    border="2px dashed grey"
    borderColor="grey.500"
    borderRadius={3}
    height={400}
    justifyContent="flex-end"
    padding={4}
    width="100%"
  >
    <Stack
      direction="row"
      gap={2}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Typography>Start your first case</Typography>
      <Button component={RouterLink} variant="contained" to="/debrief/create">
        New Cases
      </Button>
    </Stack>
  </Stack>
);
