import * as api from "../../../services/api";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slider,
  SliderThumb,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { color, colorsKey2 } from "./colors";
import { memo, useState } from "react";

import { AggregateTag } from "../../components/AggregateTag";
import Close from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import { OPINION_COUNT_LIMIT } from "../../../services/models";
import { useLowSampleThreshold } from "../../../services/hooks";
import { StatPieGraphText } from "../../components";
import { convertBase64ToBlob } from "../../../services/files";
import { useMutation } from "@tanstack/react-query";

const DeBriefCaseSummary = memo(
  ({
    rhetoric_juris_score,
    rhetoric_tone_score,
    rhetoric_sentiment_score,
    rhetoric_grade,
    rhetoric_score,
    display_name,
    id, // judge_id
    court,
    judge_opinions_count,
    brief_id,
    brief_version_id,
  }) => {
    const [shareOpen, setShareOpen] = useState(false);
    const theme = useTheme();

    const LowSampleThreshold = useLowSampleThreshold();

    return (
      <>
        <Paper>
          <Stack sx={{ padding: 5, gap: 4 }}>
            <Stack
              sx={{
                alignItems: "center",
                flexDirection: ["column", "column", "row"],
                justifyContent: "space-between",
              }}
            >
              <Stack spacing={1}>
                {id !== -1 && (
                  <Typography variant="h3" as="h1">
                    {display_name}
                    {judge_opinions_count < OPINION_COUNT_LIMIT ? (
                      <Tooltip
                        sx={{ marginLeft: -1, marginTop: -1 }}
                        title="Limited Sample"
                        placement="right"
                      >
                        <IconButton>
                          <InfoIcon
                            color="primary"
                            sx={{ width: 15, height: 15 }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Typography>
                )}
                {court?.name &&
                  (id !== -1 ? (
                    <Typography variant="h5" as="h3">
                      Judge at{" "}
                      {court.is_court_x
                        ? court.name
                        : `${court.type}, ${court.name}`}
                    </Typography>
                  ) : (
                    <Typography variant="h3" as="h1">
                      {court.is_court_x
                        ? court.name
                        : `${court.type}, ${court.name}`}
                      {court.court_opinions_count <
                        court.judges_count * LowSampleThreshold &&
                      !court.is_court_x ? (
                        <Tooltip
                          sx={{ marginLeft: -1, marginTop: -1 }}
                          title="Limited Sample"
                          placement="right"
                        >
                          <IconButton>
                            <InfoIcon
                              color="primary"
                              sx={{ width: 15, height: 15 }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Typography>
                  ))}
              </Stack>
              <Button
                variant="outlined"
                color="tertiary"
                onClick={() => setShareOpen(true)}
              >
                Share
              </Button>
            </Stack>

            {(court.is_aggregate === 1 || court.is_court_x === 1) && (
              <Stack>
                <AggregateTag
                  sx={{
                    ...(court.is_court_x
                      ? { border: `2px solid ${theme.palette.primary.main}` }
                      : {}),
                    marginTop: -2,
                    width: "15rem",
                  }}
                  tooltipText={
                    court.is_court_x
                      ? "This court represents the average data across all available judges on the platform"
                      : undefined
                  }
                />
              </Stack>
            )}

            <Stack sx={{ flexDirection: ["column", "column", "row"], gap: 4 }}>
              <Stack sx={{ flex: 1 }}>
                <StatPieGraphText
                  color={color(rhetoric_score)}
                  value={rhetoric_score}
                  comparing={false}
                  dimension={225}
                  thickness={0.8}
                  typeVariant="display"
                  labelVariant="h4"
                />
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", marginTop: 1 }}
                >
                  {`Rhetoric Score: ${rhetoric_grade}`}
                </Typography>
              </Stack>
              <Stack sx={{ flex: 2, gap: 3, justifyContent: "center" }}>
                <Range
                  label="Jurisprudence"
                  color={color(rhetoric_juris_score, colorsKey2)}
                  value={rhetoric_juris_score}
                />
                <Range
                  label="Sentiment"
                  color={color(rhetoric_sentiment_score, colorsKey2)}
                  value={rhetoric_sentiment_score}
                />
                <Range
                  label="Tone"
                  color={color(rhetoric_tone_score, colorsKey2)}
                  value={rhetoric_tone_score}
                />
              </Stack>
            </Stack>
          </Stack>
        </Paper>
        <ShareModal
          brief_id={brief_id}
          brief_version_id={brief_version_id}
          open={shareOpen}
          handleClose={() => setShareOpen(false)}
        />
      </>
    );
  }
);

const Range = memo(({ label = "label", value = 50, color = "primary" }) => {
  return (
    <Stack sx={{ alignItems: "center", flexDirection: "row", gap: 4 }}>
      <Box sx={{ flex: 1, mt: -1.5 }}>
        <Typography variant="h4" sx={{ textAlign: "right" }}>
          {label}
        </Typography>
      </Box>
      <Box sx={{ flex: 2 }}>
        <MuiSlider
          sx={{ color }}
          defaultValue={value}
          value={value}
          slots={{
            thumb: (props) => <MuiThumb value={value} {...props} />,
          }}
          onMouseDown={(e) => e.preventDefault()}
          onTouchStart={(e) => e.preventDefault()}
        />
      </Box>
    </Stack>
  );
});

const MuiSlider = styled(Slider)(({ theme }) => ({
  "&.MuiSlider-root": {
    cursor: "default",
    pointerEvents: "none",
  },
  "& .MuiSlider-thumb": {
    height: 40,
    width: 40,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "none",
      cursor: "default",
    },
  },
  "& .MuiSlider-track": {
    height: 15,
    "&:focus, &:hover, &.Mui-active": {
      cursor: "default",
    },
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#bfbfbf",
    height: 15,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "none",
      cursor: "default",
    },
  },

  ".MuiSlider-thumb:not(.MuiSlider-active)": {
    transition: "left 1s ease-in",
  },

  ".MuiSlider-track": {
    transition: "width 1s ease-in",
  },
}));

const MuiThumb = ({ value, ...other }) => (
  <SliderThumb
    {...other}
    sx={{
      background: "none",
      marginLeft: 2,
      "&:before": { display: "none" },
    }}
  >
    <Typography variant="caption" color="#000">
      {value}
    </Typography>
  </SliderThumb>
);

const ShareModal = ({ open, handleClose, brief_id, brief_version_id }) => {
  const [type, setType] = useState("full");
  const [loading, setLoading] = useState(false);

  const data = {
    report_type: type,
    brief_id,
    brief_version_id,
  };

  const { mutateAsync } = useMutation({
    mutationKey: [type, brief_id, brief_version_id],
    mutationFn: async () => {
      try {
        setLoading(true);

        await api.post(`/briefs/create_pdf`, {
          body: JSON.stringify(data),
        });

        const response = new Promise((resolve, reject) => {
          let attempt = 1;

          const timer = setInterval(async () => {
            try {
              attempt = attempt + 1;

              const response = await api.get(
                `/briefs/get_pdf?${new URLSearchParams(data).toString()}`
              );

              if (response.pdf) {
                resolve(response);
                clearInterval(timer);
              }

              if (attempt === 7) {
                reject();
                clearInterval(timer);
              }
            } catch (error) {
              reject(error);
            }
          }, 2000);
        });

        const { pdf } = await response;

        if (pdf) {
          const file = convertBase64ToBlob(pdf);
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }

        setLoading(false);
      } catch (error) {
        console.log({ error });
      }
    },
  });

  const handleSetType = (event, newType) => {
    setType((oldType) => (newType ? newType : oldType));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogActions sx={{ marginBottom: 0, paddingBottom: 0 }}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogTitle
        variant="h1"
        sx={{
          textAlign: "center",
          marginBottom: 1,
          marginTop: -1,
        }}
      >
        Please select the report you want to generate:
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: 2,
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={type}
            onChange={handleSetType}
            exclusive
            disabled={loading}
            sx={{ marginBottom: 2 }}
          >
            <ToggleButton value="full" variant="contained">
              Full Report
            </ToggleButton>
            <ToggleButton value="partial" variant="contained">
              Partial Report
            </ToggleButton>
            <ToggleButton value="scores_only" variant="contained">
              Scores Only
            </ToggleButton>
          </ToggleButtonGroup>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            onClick={mutateAsync}
            disabled={!type}
          >
            Generate Report
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export { DeBriefCaseSummary };
