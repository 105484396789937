import { Box, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { Remark } from "react-remark";
import { Simple } from "../../layouts";
import { UserRegistrationForm } from "./UserRegistrationForm";
import { useNavigate } from "react-router-dom";
import { useSuspenseQuery } from "@tanstack/react-query";
import { userOptions } from "../../../services/queries";

export const UserRegistration = () => {
  const navigate = useNavigate();
  const { data: user } = useSuspenseQuery(userOptions());
  const [data, setData] = useState("");

  useEffect(() => {
    if (!user.user_agreement_signature_needed) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    const load = async () => {
      const data = await fetch(`/md/terms.md`);
      setData(await data.text());
    };

    if (!data) {
      load();
    }
  });

  return (
    <Simple>
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box width="100%" padding={[4, 6]}>
          <Paper>
            <Stack padding={5}>
              <Stack gap={1} mb={3}>
                <Typography variant="h3">Complete Registration</Typography>
                <Typography>
                  Please fill out the registration form below and review and
                  accept the user agreement to continue.
                </Typography>
              </Stack>
              <UserRegistrationForm>
                <Box sx={{ maxHeight: "25vh", overflow: "auto" }}>
                  <Remark>{data}</Remark>
                </Box>
              </UserRegistrationForm>
            </Stack>
          </Paper>
        </Box>
      </Stack>
    </Simple>
  );
};
