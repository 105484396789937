import { Box, Button, IconButton, Stack } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "../TextField";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

export const TextSearchJudges = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues = {
    query: searchParams.get("query") ?? "",
  };

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues,
  });
  const onSubmit = async (data) => {
    setSearchParams(data);
    hotjar.event("search-judges-submit");
  };

  const handleReset = () => {
    setSearchParams([]);
    reset();
    hotjar.event("search-judges-reset");
  };

  const query = watch("query");

  useEffect(() => {
    setValue("query", searchParams.get("query") ?? "");
  }, [searchParams, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack alignItems="center" gap={2} direction="row">
        <Box sx={{ flex: 1, position: "relative" }}>
          <TextField
            control={control}
            placeholder="Search Judges"
            label="Search Judges"
            fullWidth
            name="query"
            trim={false}
          />
          {query.length > 2 && (
            <IconButton
              onClick={handleReset}
              type="button"
              size="small"
              sx={{
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        {query.length > 2 && <Button type="submit">Search</Button>}
      </Stack>
    </form>
  );
};
