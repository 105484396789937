import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { Remark } from "react-remark";
import { UserAgreementForm } from "./UserAgreementForm";
import { useAtom } from "jotai";
import { useAuth } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { userAgreementModal } from "../../../services/store";
import { userOptions } from "../../../services/queries";

export const UserAgreement = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useAtom(userAgreementModal);
  const [data, setData] = useState("");
  const { handleLogout } = useAuth();

  const handleLogoutExperience = async () => {
    await handleLogout();
    navigate("/login");
  };

  const { data: user } = useQuery(userOptions());

  useEffect(() => {
    const load = async () => {
      const data = await fetch(`/md/terms.md`);
      setData(await data.text());
    };

    if (!data) {
      load();
    }
  });

  useEffect(() => {
    setOpen(user?.user_agreement_signature_needed);
  }, [setOpen, user]);

  return (
    <Modal open={open}>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Box
          sx={{
            background: "white",
            borderRadius: 3,
            maxWidth: "50%",
            minWidth: 800,
            padding: 5,
          }}
        >
          <Stack>
            <Typography variant="h1">User Agreement</Typography>
          </Stack>
          <Box sx={{ maxHeight: 500, overflow: "auto" }}>
            <Remark>{data}</Remark>
          </Box>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: 5,
            }}
          >
            <UserAgreementForm />
            <Button variant="text" onClick={handleLogoutExperience}>
              Logout
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Modal>
  );
};
