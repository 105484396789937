import { Box, Button, Stack, Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { DeBriefCard } from "../../components";
import Grid from "@mui/material/Unstable_Grid2";
import { NavLink } from "react-router-dom";

export const DeBriefItems = ({ items, setSelected, selected }) => {
  return (
    <>
      <Stack>
        <Box
          backgroundColor="white"
          paddingY={4}
          paddingX={[0, 4]}
          position="sticky"
          top={0}
          zIndex={10}
        >
          <Grid container xs={12}>
            <Grid xs={12}>
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  component={NavLink}
                  to="/debrief/create"
                  variant="contained"
                  data-cy="new-case-button"
                >
                  New Case
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box backgroundColor="grey.100" paddingY={4} paddingX={[0, 4]}>
          <Grid container rowSpacing={4} xs={12} component={TransitionGroup}>
            {items?.map((item, index) => (
              <Collapse key={item.id} sx={{ width: "100%" }}>
                <Grid xs={12}>
                  <DeBriefCard
                    handleSelect={setSelected}
                    active={selected === item.id}
                    index={index}
                    {...item}
                  />
                </Grid>
              </Collapse>
            ))}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};
