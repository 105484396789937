import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Controller } from "react-hook-form";
import InfoIcon from "@mui/icons-material/Info";
import { ReWriteHistory } from "../ReWriteHistory";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { SelectField } from "../SelectField";
import { TextField } from "../TextField";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { useCopyToClipboard } from "../../../services/hooks/useCopyToClipboard";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useReWrite } from "../../../services/models/rewrite";
import { useTheme } from "@mui/material/styles";

const marks = [
  {
    value: 1,
    label: "Slight",
  },
  {
    value: 2,
    label: "Moderate",
  },
  {
    value: 3,
    label: "Significant",
  },
];

const more_less = [
  {
    value: "more",
    label: "More",
  },
  {
    value: "less",
    label: "Less",
  },
];

const downvote = [
  {
    value: "Select Reason",
    label: "Select Reason",
  },
  {
    value: "Too wordy",
    label: "Too wordy",
  },
  {
    value: "Too concise",
    label: "Too concise",
  },
  {
    value: "Too aggressive",
    label: "Too aggressive",
  },
  {
    value: "Too passive",
    label: "Too passive",
  },
  {
    value: "Changed legal content",
    label: "Changed legal content",
  },
  {
    value: "Too formal",
    label: "Too formal",
  },
  {
    value: "Too informal",
    label: "Too informal",
  },
];

const defaultValues = {
  text: "",
  cat: 0,
  sub_cat: 0,
  scale: 1,
  more_less: "less",
  regenerate: false,
  feedback_previous_regen: "",
  feedback_selected_response: "Select Reason",
  feedback_custom_response: "",
  vote: "",
};

const CHARACTER_LIMIT = 750;

function setMarksMax(length) {
  if (length >= 300) {
    return 3;
  }

  if (length >= 150) {
    return 2;
  }

  return 1;
}

export function ReWrite({
  currentTab,
  items,
  subItems = {},
  handleSetTab,
  brief_id,
  brief_version_id,
}) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [refineVisible, setRefineVisible] = useState(true);
  const { sendReWrite, getReWrite } = useReWrite();
  const [, copy] = useCopyToClipboard();
  const theme = useTheme();

  const { handleSubmit, control, watch, setValue, getValues } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setValue("feedback_previous_regen", "");

    const sub_cat = subcategories.find(
      (sub) => sub.value === data.sub_cat
    ).label;

    const response = await sendReWrite({
      brief_id,
      brief_version_id,
      sub_cat,
      text: data.text,
      scale: data.scale,
      more_less: data.more_less,
      feedback_selected_response: data.feedback_selected_response,
      feedback_custom_response: data.feedback_custom_response,
      feedback_previous_regen: data.feedback_previous_regen,
      regenerate: data.regenerate,
      vote: data.vote,
    });

    const { text } = await getReWrite(response);

    setValue("feedback_previous_regen", text);
    setValue("vote", "");
    setValue("regenerate", false);
    setLoading(false);

    queryClient.invalidateQueries({
      queryKey: ["rewrite_history", brief_id, brief_version_id],
    });
  };

  const handleCopy = () => {
    copy(getValues("feedback_previous_regen"));
  };

  const handleRestart = async () => {
    setValue("regenerate", true);
    setValue("feedback_selected_response", "");
    const data = getValues();
    onSubmit(data);
  };

  const handleRefine = async () => {
    setValue("regenerate", false);

    setValue("vote", "down");
    setValue("feedback_custom_response", "");
  };

  const handleUpVote = async () => {
    setValue("regenerate", false);
    setValue("vote", "up");
    const data = getValues();

    const sub_cat = subcategories.find(
      (sub) => sub.value === data.sub_cat
    ).label;

    await sendReWrite({
      brief_id,
      brief_version_id,
      sub_cat,
      text: data.text,
      regenerate: false,
      feedback_previous_regen: data.feedback_previous_regen,
      vote: data.vote,
    });
  };

  const handleReset = () => {
    setValue("regenerate", false);
    setValue("feedback_previous_regen", "");
    setValue("scale", 1);
    setValue("text", "");
    setValue("vote", "");
  };

  const inputText = watch("text");
  const inputCat = watch("cat");
  const inputMoreLess = watch("more_less");
  const generatedText = watch("feedback_previous_regen");
  const vote = watch("vote");

  const isValid = inputText !== "" && inputCat;

  const categories = items.slice(1, 4).map((item, index) => ({
    value: index + 1,
    label: item,
  }));

  const subcategories =
    Object.keys(subItems)?.map((item, index) => ({
      value: index,
      label: item,
    })) || [];

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "cat") {
        handleSetTab(false, value.cat);
      }

      if (name === "more_less" || name === "sub_cat" || name === "cat") {
        setValue("vote", "");
      }
    });

    return () => subscription.unsubscribe();
  }, [handleSetTab, setValue, watch]);

  useEffect(() => {
    setValue("cat", currentTab);
  }, [currentTab, setValue]);

  useEffect(() => {
    if (vote !== "down") {
      setRefineVisible(true);
    }
  }, [vote]);

  const handleRefineClick = () => {
    handleRefine();
    setRefineVisible(false);
  };

  return (
    <Stack sx={{ py: 5, px: 3, gap: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <TextField
            control={control}
            placeholder="Paste your excerpt here."
            fullWidth
            name="text"
            multiline
            maxRows={10}
            rules={{ required: "This field is required" }}
            trim={false}
            sx={{ maxHeight: 200, overflow: "auto" }}
            inputProps={{ maxLength: CHARACTER_LIMIT }}
            helperText={`${inputText.length}/${CHARACTER_LIMIT}`}
          />
          <Box>
            <Typography variant="caption">I want to adjust the</Typography>
            <SelectField
              name="cat"
              control={control}
              options={[{ value: 0, label: "Select One" }, ...categories]}
              rules={{ required: "This field is required" }}
            />
            <Typography variant="caption">of this excerpt</Typography>
          </Box>
          {subcategories?.length ? (
            <Box>
              <Typography variant="caption">I want to make it</Typography>
              <Stack sx={{ gap: 1 }}>
                <SelectField
                  name="more_less"
                  control={control}
                  options={more_less}
                  rules={{ required: "This field is required" }}
                  size="small"
                />
                <SelectField
                  name="sub_cat"
                  control={control}
                  options={subcategories}
                  rules={{ required: "This field is required" }}
                  size="small"
                />
                {inputMoreLess === "more" && (
                  <Typography variant="caption">
                    ReWrite may change the original meaning of your excerpt,
                    please review any changes thoroughly before including them
                    in your document.
                  </Typography>
                )}
              </Stack>
            </Box>
          ) : null}
          {inputMoreLess === "more" && (
            <Box sx={{ px: 4 }}>
              <Controller
                name="scale"
                control={control}
                render={({ field }) => (
                  <Slider
                    disabled={inputText.length < 150}
                    marks={marks}
                    min={1}
                    max={setMarksMax(inputText.length)}
                    step={1}
                    {...field}
                  />
                )}
              />
            </Box>
          )}
          {vote !== "down" && (
            <Box>
              <Button
                type="submit"
                disabled={!isValid || loading}
                variant="contained"
                fullWidth
                sx={{ textTransform: "none" }}
              >
                {loading ? <CircularProgress size={30} /> : "ReWrite"}
              </Button>
            </Box>
          )}
        </Stack>
        <Divider sx={{ marginBlock: 2 }} />
        <Stack sx={{ gap: 1 }}>
          <TextField
            placeholder="Your text will appear here"
            name="feedback_previous_regen"
            control={control}
            multiline
            disabled
          />
          <Stack
            sx={{ flexDirection: "row", justifyContent: "center", gap: 2 }}
          >
            <ReWriteHistory
              brief_id={brief_id}
              brief_version_id={brief_version_id}
            />
            <IconButton onClick={handleCopy} disabled={!generatedText}>
              <Tooltip title="Copy">
                <ContentCopyIcon sx={{ width: 20, height: 20 }} />
              </Tooltip>
            </IconButton>

            <IconButton onClick={handleRestart} disabled={!generatedText}>
              <Tooltip title="Retry">
                <RestartAltIcon sx={{ width: 20, height: 20 }} />
              </Tooltip>
            </IconButton>

            <IconButton onClick={handleUpVote} disabled={!generatedText}>
              <Tooltip title="Up Vote the result">
                <ThumbUpAltIcon sx={{ width: 20, height: 20 }} />
              </Tooltip>
            </IconButton>

            <Divider flexItem orientation="vertical" />

            <IconButton
              onClick={handleReset}
              color="error"
              disabled={!generatedText}
            >
              <Tooltip title="Reset">
                <ClearIcon sx={{ width: 20, height: 20 }} />
              </Tooltip>
            </IconButton>
          </Stack>
          <Stack
            alignItems="center"
            flexDirection="row"
            gap={1}
            justifyContent="flex-end"
          >
            <Tooltip
              title="Retry this prompt by providing additional context"
              placement="left"
            >
              <IconButton>
                <InfoIcon color="primary" sx={{ width: 15, height: 15 }} />
              </IconButton>
            </Tooltip>
          </Stack>
          {refineVisible && (
            <Stack>
              <Box>
                <Button
                  color="secondary"
                  onClick={handleRefineClick}
                  disabled={!generatedText || vote === "up"}
                  variant="contained"
                  fullWidth
                  sx={{
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.tertiary.hover,
                    },
                  }}
                >
                  Refine
                </Button>
              </Box>
            </Stack>
          )}
          {vote === "down" && (
            <Stack sx={{ gap: 1 }}>
              <SelectField
                name="feedback_selected_response"
                control={control}
                options={downvote}
                rules={{ required: "This field is required" }}
              />
              <TextField
                control={control}
                placeholder="Leave any other feedback"
                fullWidth
                name="feedback_custom_response"
                multiline
                maxRows={10}
                trim={false}
                sx={{ maxHeight: 200, overflow: "auto" }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={!isValid || loading}
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.tertiary.hover,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  "Retry With Feedback"
                )}
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
    </Stack>
  );
}
