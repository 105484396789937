import * as Views from "../../ui/views";

import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import { fetchAuthSession } from "aws-amplify/auth";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [ok, setOk] = useState(false);

  useEffect(() => {
    const load = async () => {
      const { tokens } = await fetchAuthSession({ bypassCache: true });
      setLoading(false);
      setOk(!!tokens?.accessToken);
    };

    load();
  }, []);

  if (loading) return <CircularProgress size="sm" />;

  if (!ok) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<Views.Error />}>
      <Route
        index
        element={
          <PrivateRoute>
            <Views.Home />
          </PrivateRoute>
        }
      />
      <Route path="login" element={<Views.Login />} />
      <Route path="logout" element={<Views.Logout />} />
      <Route path="reset/request" element={<Views.RequestReset />} />
      <Route path="reset/password" element={<Views.ResetPassword />} />
      <Route
        path="registration"
        element={
          <PrivateRoute>
            <Views.UserRegistration />
          </PrivateRoute>
        }
      />
      <Route path="doctrina">
        <Route
          index
          element={
            <PrivateRoute>
              <Views.Doctrinas />
            </PrivateRoute>
          }
        />
        <Route
          path="compare"
          element={
            <PrivateRoute>
              <Views.DoctrinaCompare />
            </PrivateRoute>
          }
        />
        <Route
          path=":id"
          element={
            <PrivateRoute>
              <Views.Court />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="debrief">
        <Route
          index
          element={
            <PrivateRoute>
              <Views.DeBrief />
            </PrivateRoute>
          }
        />
        <Route
          path="create"
          element={
            <PrivateRoute>
              <Views.DeBriefCreate />
            </PrivateRoute>
          }
        />
        <Route path="case">
          <Route
            path=":id"
            element={
              <PrivateRoute>
                <Views.DeBriefCase />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
      <Route path="judges">
        <Route
          index
          element={
            <PrivateRoute>
              <Views.Judges />
            </PrivateRoute>
          }
        />
        <Route
          path=":id"
          element={
            <PrivateRoute>
              <Views.Judge />
            </PrivateRoute>
          }
        />
        <Route
          path="compare"
          element={
            <PrivateRoute>
              <Views.JudgesCompare />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="cicero">
        <Route
          index
          element={
            <PrivateRoute>
              <Views.Cicero />
            </PrivateRoute>
          }
        />
        <Route
          path="create"
          element={
            <PrivateRoute>
              <Views.CiceroCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="case/:id"
          element={
            <PrivateRoute>
              <Views.CiceroCase />
            </PrivateRoute>
          }
        />
      </Route>
    </Route>
  )
);

const Routing = () => <RouterProvider router={router} />;

export default Routing;
