import * as api from "../../../services/api";

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Suspense, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HistoryIcon from "@mui/icons-material/History";
import PreviewIcon from "@mui/icons-material/Preview";
import { useCopyToClipboard } from "../../../services/hooks/useCopyToClipboard";
import { useSuspenseQuery } from "@tanstack/react-query";

const scaleLookup = ["Slight", "Moderate", "Significant"];

export const ReWriteHistory = (props) => {
  return (
    <Suspense loading={<CircularProgress size={30} />}>
      <Fetch {...props} />
    </Suspense>
  );
};

const Fetch = ({ brief_id, brief_version_id }) => {
  const [open, setOpen] = useState(false);

  const { data } = useSuspenseQuery({
    queryKey: ["rewrite_history", brief_id, brief_version_id],
    queryFn: async () => {
      return await api.get(
        `/briefs/rewrite_history?brief_id=${brief_id}&brief_version_id=${brief_version_id}`
      );
    },
  });

  return (
    data && (
      <>
        <IconButton onClick={() => setOpen(true)}>
          <Tooltip title="History">
            <HistoryIcon size={30} />
          </Tooltip>
        </IconButton>
        <Divider flexItem orientation="vertical" />
        <Modal open={open} onClose={() => setOpen(false)}>
          <Stack justifyContent="center" alignItems="center" height="100vh">
            <Box maxWidth={1200} width="80%" position="relative">
              <Paper elevation={4}>
                <Stack
                  paddingX={4}
                  paddingTop={3}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h3">ReWrite History</Typography>
                  <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ maxHeight: "80vh", overflowY: "auto" }}>
                  <Box
                    paddingX={4}
                    paddingBottom={3}
                    sx={{ overflowX: "auto" }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width={500}>
                            <Typography variant="caption">Output</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">Category</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              Sub Category
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">Less/More</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">Scale</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">Created</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.map((row) => (
                          <Row key={row.created_timestamp} {...row} />
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Stack>
        </Modal>
      </>
    )
  );
};

function Row(row) {
  const [open, setOpen] = useState();
  const [, copy] = useCopyToClipboard();

  const handleCopy = () => {
    copy(row.regen_text);
  };

  const handlToggle = () => {
    setOpen((open) => !open);
  };

  const date = new Date(row.created_timestamp);

  return (
    <>
      <TableRow key={row.created_timestamp}>
        <TableCell width={500}>
          <Stack
            sx={{
              flexDirection: "row",
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <Stack>
              <Tooltip title="Copy Regenerated Text">
                <IconButton onClick={handleCopy} size="small">
                  <ContentCopyIcon sx={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Original Text">
                <IconButton onClick={handlToggle} size="small">
                  <PreviewIcon sx={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>
            </Stack>
            {row.regen_text}
          </Stack>
        </TableCell>
        <TableCell sx={{ textTransform: "capitalize" }}>
          {row.category}
        </TableCell>
        <TableCell>{row.sub_cat}</TableCell>
        <TableCell sx={{ textTransform: "capitalize" }}>
          {row.more_less}
        </TableCell>
        <TableCell>{scaleLookup[row.scale - 1]}</TableCell>
        <TableCell>
          {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colspan="6">{row.orig_text}</TableCell>
        </TableRow>
      )}
    </>
  );
}
