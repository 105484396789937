import { CircularProgress, Stack, Box } from "@mui/material";
import { Suspense, useState } from "react";

import { Default } from "../../layouts";
import { JudgeContainer } from "./JudgeContainer";
import { JudgeCourtSummaryTabs } from "../../components/JudgeCourtSummaryTabs";
import { JudgeFilter } from "./JudgeFilter";
import { memo } from "react";
import { useParams } from "react-router-dom";

const JudgeView = memo(() => {
  const { id } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [isJudgeX, setIsJudgeX] = useState(false);

  const handleSetSubject = (event, value) => {
    setSubjects(value);
  };

  return (
    <Default>
      <Stack sx={{ p: [0, 4], gap: 2 }}>
        <JudgeFilter
          handleSetSubject={handleSetSubject}
          id={id}
          subjects={subjects}
        />
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          }
        >
          <JudgeContainer
            id={id}
            subjects={subjects}
            handleSetSubject={handleSetSubject}
            setIsJudgeX={setIsJudgeX}
          />
        </Suspense>
        <JudgeCourtSummaryTabs isJudge judgeCourtId={id} isJudgeX={isJudgeX} />
      </Stack>
    </Default>
  );
});

export const Judge = () => <JudgeView />;

export default Judge;
