import {
  Autocomplete,
  FormGroup,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Stack } from "@mui/system";
import { doctrinaNamesOptions } from "../../../services/queries";
import { uploadDeBriefState } from "../../../services/store";
import { useCallback, useState } from "react";
import { useLowSampleThreshold } from "../../../services/hooks";
import { useSetAtom } from "jotai";
import { useSuspenseQuery } from "@tanstack/react-query";

export const SelectCourt = () => {
  const setBrief = useSetAtom(uploadDeBriefState);
  const { data: courts } = useSuspenseQuery(doctrinaNamesOptions());
  const LowSampleThreshold = useLowSampleThreshold();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = useCallback(
    (event, value) => {
      setSelectedOption(value);
      setBrief((current) => ({
        ...current,
        court: value?.id || false,
        courtName: value?.label || false,
        isAggregate: value?.isAggregate || false,
        isCourtX: value?.isCourtX || false,
        judge: "",
        judgeName: "",
      }));
    },
    [setBrief]
  );

  return (
    <Stack spacing={3}>
      <form>
        <FormGroup>
          <Autocomplete
            onChange={handleChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.label}
            options={courts?.map((court) => ({
              label: court.name,
              id: court.id,
              courtOpinionsCount: court.court_opinions_count,
              judgesCount: court.judges_count,
              isAggregate: !!court.is_aggregate,
              isCourtX: !!court.is_court_x,
            }))}
            renderOption={(props, option) => (
              <li {...props}>
                {option.label}
                {option.isAggregate && (
                  <Tooltip title="This is an aggregate court, which means the data for this court cannot be attributed to individual judges.">
                    <IconButton size="small" sx={{ marginLeft: 1 }}>
                      <InfoIcon
                        color="secondary"
                        sx={{ width: 15, height: 15 }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {option.isCourtX && (
                  <Tooltip title="This court represents the average data across all available judges on the platform">
                    <IconButton size="small" sx={{ marginLeft: 1 }}>
                      <InfoIcon
                        color="secondary"
                        sx={{
                          marginRight: "0.5rem",
                          width: 15,
                          height: 15,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {option.courtOpinionsCount <
                option.judgesCount * LowSampleThreshold ? (
                  <Tooltip
                    sx={{ marginLeft: 1, marginTop: -1 }}
                    title="Limited Sample"
                  >
                    <IconButton size="small">
                      <InfoIcon
                        color="primary"
                        sx={{ width: 15, height: 15 }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Courts"
                label="Choose a court"
              />
            )}
          />
        </FormGroup>
      </form>

      {selectedOption &&
        (selectedOption.isAggregate ||
          selectedOption.isCourtX ||
          selectedOption.courtOpinionsCount <
            selectedOption.judgesCount * LowSampleThreshold) && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <InfoIcon color="secondary" sx={{ width: 20, height: 20 }} />
            <Typography variant="body2">
              {selectedOption.isAggregate
                ? "This is an aggregate court."
                : selectedOption.isCourtX
                ? "This court represents the average data across all available judges."
                : selectedOption.courtOpinionsCount <
                  selectedOption.judgesCount * LowSampleThreshold
                ? "Limited Sample"
                : ""}
            </Typography>
          </Stack>
        )}
    </Stack>
  );
};
