import { Stack, Typography } from "@mui/material";

export const SearchEmpty = ({ text = "No results found" }) => (
  <Stack
    backgroundColor="white"
    justifyContent="center"
    paddingY={5}
    paddingX={4}
  >
    <Typography>{text}</Typography>
  </Stack>
);
