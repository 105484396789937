import { Box, Paper, Stack, Typography } from "@mui/material";

export const ProductCard = ({ heading, content, action }) => (
  <Paper
    sx={{ overflow: "hidden", borderRadius: 3, height: "100%" }}
    elevation={5}
  >
    <Box
      backgroundColor="primary.main"
      height="100%"
      padding={3}
      spacing={3}
      color="primary.contrastText"
    >
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Stack direction="row">
            <Typography variant="h1">{heading}</Typography>
          </Stack>
          <Typography>{content}</Typography>
        </Stack>
        <Box>{action}</Box>
      </Stack>
    </Box>
  </Paper>
);
