import * as api from "../api";

export function useReWrite() {
  const sendReWrite = async (body) => {
    const data = await api.post("/briefs/do_rewrite_for_brief", {
      body: JSON.stringify(body),
    });

    return data[0];
  };

  const getReWrite = async (options) => {
    // ?brief_id=780&brief_version_id=1019&sub_cat=formal&text_hash=4cc10c30c89b4d7508e937b6e1385441

    return await new Promise((resolve) => {
      let count = 0;
      const params = new URLSearchParams(options).toString();

      const interval = setInterval(async () => {
        count++;
        const response = await api.get(
          `/briefs/get_cached_rewrite_for_brief?${params}`
        );

        if (response[0].text || count === 15) {
          resolve(response[0]);
          clearInterval(interval);
        }
      }, 2000);
    });
  };

  return { sendReWrite, getReWrite };
}
