import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Folder as FolderIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";

import { AggregateTag } from "../AggregateTag";
import CancelIcon from "@mui/icons-material/Cancel";
import { DeBriefCardFilter } from "./DeBriefCardFilter";
import { DeBriefCardItem } from "./DeBriefCardItem";
import { DeBriefCardSummary } from "./DeBriefCardSummary";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import { OPINION_COUNT_LIMIT } from "../../../services/models";
import { SelectField } from "../SelectField";
import { TextField } from "../TextField";
import { briefOptions } from "../../../services/queries/index.js";
import { documentTypeOptions } from "../DeBriefUploadModal/DeBriefUploadModal.jsx";
import { useContainerQuery } from "react-container-query";
import { useDeleteBrief } from "../../../services/models/briefs";
import { useForm } from "react-hook-form";
import { useLowSampleThreshold } from "../../../services/hooks";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useUpdateBrief } from "../../../services/models/briefs";

const query = {
  "width-between-500-and-820": {
    minWidth: 500,
    maxWidth: 820,
  },
};

export const DeBriefCard = ({
  id,
  judge,
  modified_date,
  active,
  name,
  brief_version_ids,
}) => {
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const [params, containerRef] = useContainerQuery(query);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { mutateAsync: deleteBriefAsync } = useDeleteBrief();
  const { mutateAsync: updateBriefAsync } = useUpdateBrief();
  const [loading, setLoading] = useState(false);

  const LowSampleThreshold = useLowSampleThreshold();

  const versions = brief_version_ids.split(",");
  const [versionId, setVersionId] = useState(versions.slice(-1)[0]);

  const { data, isLoading, isError, refetch } = useQuery(briefOptions(id));

  const [selectedVersion, setSelectedVersion] = useState(
    data?.versions?.find(
      (version) => version.brief_version_id === +versionId
    ) || {}
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      case_name: selectedVersion.case_name,
      client_number: selectedVersion.client_number,
      matter_number: selectedVersion.matter_number,
      document_type: selectedVersion.document_type,
      rhetoric_grade: selectedVersion.rhetoric_grade,
      rhetoric_score: selectedVersion.rhetoric_score,
      rhetoric_juris_score: selectedVersion.rhetoric_juris_score,
      rhetoric_sentiment_score: selectedVersion.rhetoric_sentiment_score,
      rhetoric_tone_score: selectedVersion.rhetoric_tone_score,
      brief_id: id,
      brief_version_id: versionId,
    },
  });

  useEffect(() => {
    if (data?.versions) {
      const newSelectedVersion =
        data.versions.find(
          (version) => version.brief_version_id === +versionId
        ) || {};
      setSelectedVersion(newSelectedVersion);
      reset({
        case_name: newSelectedVersion.case_name,
        client_number: newSelectedVersion.client_number,
        matter_number: newSelectedVersion.matter_number,
        document_type: newSelectedVersion.document_type,
        rhetoric_grade: newSelectedVersion.rhetoric_grade,
        rhetoric_score: newSelectedVersion.rhetoric_score,
        rhetoric_juris_score: newSelectedVersion.rhetoric_juris_score,
        rhetoric_sentiment_score: newSelectedVersion.rhetoric_sentiment_score,
        rhetoric_tone_score: newSelectedVersion.rhetoric_tone_score,
        brief_id: id,
        brief_version_id: versionId,
      });
    }
  }, [data, versionId, reset, id]);

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const deleteItem = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  const goToItem = useCallback(
    () => navigate(`/debrief/case/${id}?version_id=${versionId}`),
    [id, navigate, versionId]
  );

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleDelete = useCallback(async () => {
    setProcessing(true);
    await deleteBriefAsync(id);
    setProcessing(false);
    setDialogOpen(false);
  }, [deleteBriefAsync, id]);

  const handleSetTab = (event, value) => setTab(value);
  const handleSetTabAlt = (event) => setTab(event.target.value);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleVersionChange = async (version) => {
    setVersionId(version);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    setTimeout(async () => {
      const payload = {
        ...values,
        brief_id: id,
        brief_version_id: String(versionId),
      };
      try {
        await updateBriefAsync(payload);
        await refetch();
      } catch (error) {
        console.error("Failed to update brief:", error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);
        }
      } finally {
        setLoading(false);
        setIsEditing(false);
      }
    }, 1000);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  if (isError) {
    return <Typography variant="h6">Error loading data...</Typography>;
  }

  return (
    <>
      <Paper
        ref={containerRef}
        elevation={5}
        sx={{
          padding: 3,
          borderRadius: 3,
          borderRight: active && `25px solid ${theme.palette.primary.main}`,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            alignItems="center"
            flexWrap={[
              "wrap",
              "wrap",
              "wrap",
              params["width-between-500-and-820"] ? "wrap" : "nowrap",
            ]}
            direction={["column", "row"]}
            gap={[2, 3]}
          >
            <Stack
              spacing={1}
              alignSelf="stretch"
              justifyContent="space-between"
              maxWidth={[
                "auto",
                "100%",
                "100%",
                params["width-between-500-and-820"] ? "100%" : 300,
              ]}
              width={[
                "100%",
                "100%",
                "100%",
                params["width-between-500-and-820"] ? "100%" : 300,
              ]}
            >
              <Stack sx={{ gap: 2 }}>
                <Stack sx={{ gap: 0 }}>
                  <Typography variant="h4" as="h2">
                    {name}
                  </Typography>
                  <Typography variant="caption" as="h3" color="text.light">
                    Updated:&nbsp;
                    {new Date(
                      Date.parse(`${modified_date} 12:00:00`)
                    ).toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1} mt={2}>
                    <Box sx={{ flex: 1 }}>
                      {versions.length > 1 ? (
                        <DeBriefCardFilter
                          briefId={id}
                          handleSetVersion={handleVersionChange}
                          selectedVersionId={versionId}
                        />
                      ) : (
                        <Typography color="primary">1 Version</Typography>
                      )}
                    </Box>
                    <Tooltip
                      title={
                        isEditing ? "Discard Changes" : "Edit Version Name"
                      }
                      placement="right"
                    >
                      <IconButton onClick={toggleEdit}>
                        {isEditing ? <CancelIcon /> : <EditIcon />}
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack spacing={0.5}>
                  {judge.display_name ? (
                    <>
                      <Typography variant="h4" as="h5">
                        {judge.display_name}
                        {judge.judge_opinions_count < OPINION_COUNT_LIMIT ? (
                          <Tooltip
                            sx={{ marginLeft: -1, marginTop: -1 }}
                            title="Limited Sample"
                            placement="right"
                          >
                            <IconButton>
                              <InfoIcon
                                color="primary"
                                sx={{ width: 15, height: 15 }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Typography>
                      <Typography variant="body2" as="h6" color="text.light">
                        {judge.court.name}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="h4" as="h5">
                        {judge.court.name}
                        {judge.court.court_opinions_count <
                        judge.court.judges_count * LowSampleThreshold ? (
                          <Tooltip
                            sx={{ marginLeft: -1, marginTop: -1 }}
                            title="Limited Sample"
                            placement="right"
                          >
                            <IconButton>
                              <InfoIcon
                                color="primary"
                                sx={{ width: 15, height: 15 }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Typography>
                    </>
                  )}
                </Stack>
                {(judge.court.is_aggregate === 1 ||
                  judge.court.is_court_x === 1) && (
                  <AggregateTag
                    sx={
                      judge.court.is_court_x
                        ? { border: `2px solid ${theme.palette.primary.main}` }
                        : {}
                    }
                    tooltipText={
                      judge.court.is_court_x
                        ? "This court represents the average data across all available judges on the platform"
                        : undefined
                    }
                  />
                )}
                <Stack sx={{ gap: 1 }}>
                  <Stack spacing={2}>
                    <Stack>
                      <Typography color="text.light" variant="caption">
                        Case Name:
                      </Typography>
                      {isEditing ? (
                        <TextField
                          control={control}
                          placeholder="Case Name"
                          fullWidth
                          name="case_name"
                          trim={false}
                          size="small"
                        />
                      ) : (
                        <Typography variant="body2">
                          {selectedVersion.case_name}
                        </Typography>
                      )}
                    </Stack>
                    <Stack>
                      <Typography color="text.light" variant="caption">
                        Client Number:
                      </Typography>
                      {isEditing ? (
                        <TextField
                          control={control}
                          placeholder="Client Number"
                          fullWidth
                          name="client_number"
                          trim={false}
                          size="small"
                        />
                      ) : (
                        <Typography variant="body2">
                          {selectedVersion.client_number}
                        </Typography>
                      )}
                    </Stack>
                    <Stack>
                      <Typography color="text.light" variant="caption">
                        Matter Number:
                      </Typography>
                      {isEditing ? (
                        <TextField
                          control={control}
                          placeholder="Matter Number"
                          fullWidth
                          name="matter_number"
                          trim={false}
                          size="small"
                        />
                      ) : (
                        <Typography variant="body2">
                          {selectedVersion.matter_number}
                        </Typography>
                      )}
                    </Stack>
                    <Stack>
                      <Typography color="text.light" variant="caption">
                        Document Type:
                      </Typography>
                      {isEditing ? (
                        <SelectField
                          placeholder="Document Type"
                          name="document_type"
                          control={control}
                          options={documentTypeOptions}
                          size="small"
                        />
                      ) : (
                        <Typography variant="body2">
                          {selectedVersion.document_type}
                        </Typography>
                      )}
                      {isEditing && (
                        <Button
                          size="small"
                          margin={0.5}
                          variant="contained"
                          endIcon={
                            loading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          type="submit"
                          style={{ marginTop: "2rem", marginBottom: "0.5rem" }}
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "Save Updates"}
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row">
                <Tooltip title="Delete" placement="right">
                  <IconButton onClick={deleteItem} cy-data="delete-case-btn">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Open Case" placement="right">
                  <IconButton onClick={goToItem} cy-data="open-case-btn">
                    <FolderIcon color="secondary" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
            {lgUp && !params["width-between-500-and-820"] && (
              <Divider orientation="vertical" flexItem />
            )}
            <Box sx={{ flex: 1, width: "100%" }}>
              <Tabs
                value={tab}
                onChange={handleSetTab}
                aria-label="basic tabs example"
                sx={{ display: ["none", "none", "block"], mb: 3 }}
              >
                <Tab label="Summary" {...a11yProps(0)} />
                <Tab label="Jurisprudence" {...a11yProps(1)} />
                <Tab label="Sentiment" {...a11yProps(2)} />
                <Tab label="Tone" {...a11yProps(3)} />
              </Tabs>
              <Select
                sx={{
                  display: ["block", "block", "none", "none"],
                  width: "100%",
                  mb: 3,
                }}
                fullWidth
                onChange={handleSetTabAlt}
                value={tab}
              >
                <MenuItem value={0}>Summary</MenuItem>
                <MenuItem value={1}>Jurisprudence</MenuItem>
                <MenuItem value={2}>Sentiment</MenuItem>
                <MenuItem value={3}>Tone</MenuItem>
              </Select>
              <Box sx={{ paddingX: [0, 4] }}>
                <Box hidden={tab !== 0}>
                  <DeBriefCardSummary
                    onClick={goToItem}
                    rhetoric_grade={selectedVersion.rhetoric_grade}
                    rhetoric_score={selectedVersion.rhetoric_score}
                    rhetoric_juris_score={selectedVersion.rhetoric_juris_score}
                    rhetoric_sentiment_score={
                      selectedVersion.rhetoric_sentiment_score
                    }
                    rhetoric_tone_score={selectedVersion.rhetoric_tone_score}
                  />
                </Box>
                <Box hidden={tab !== 1}>
                  <DeBriefCardItem
                    scores={selectedVersion.jurisprudence || []}
                    type="jurisprudence"
                    judge={judge}
                  />
                </Box>
                <Box hidden={tab !== 2}>
                  <DeBriefCardItem
                    scores={selectedVersion.sentiment || []}
                    type="sentiment"
                    judge={judge}
                  />
                </Box>
                <Box hidden={tab !== 3}>
                  <DeBriefCardItem
                    scores={selectedVersion.tone || []}
                    type="tone"
                    judge={judge}
                  />
                </Box>
              </Box>
            </Box>
          </Stack>
        </form>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="h2"
          sx={{ textAlign: "center" }}
        >
          Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography
              variant="h3"
              sx={{ fontWeight: "400", textAlign: "center" }}
              id="alert-dialog-description"
            >
              {selectedVersion.case_name
                ? `Are you sure you want to delete ${selectedVersion.case_name}?`
                : "Are you sure you want to delete this case?"}
            </Typography>
            <Typography variant="body1" color="text.light">
              Note: Deleting this Case will also delete all versions uploaded
              within it. This action cannot be undone.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={processing}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            disabled={processing}
            cy-data="delete-agree-btn"
          >
            {processing ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              "Confirm Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
