import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import {
  jurisprudence,
  sentiment,
  tone,
} from "../../../services/store/definitions";
import { useCallback, useMemo } from "react";

import { ResponsivePie } from "@nivo/pie";

const definitions = { ...jurisprudence, ...sentiment, ...tone };

export const StatPieGraphText = ({
  color = "",
  active = false,
  label = "",
  value,
  comparing = true,
  simple,
  filled = false,
  showPolarity = false,
  thickness = 0.9,
  dimension = 75,
  typeVariant = "p",
}) => {
  const theme = useTheme();

  const graphColor = active
    ? theme.palette.primary.main
    : { datum: "data.color" };
  const textColor = active
    ? theme.palette.primary.main
    : theme.typography.body1.main;

  const getColorKey = useCallback(
    (value) => {
      if (color) return color;
      if (simple && active) return "white";
      if (Math.abs(+value) < 10) return theme.palette.success.main;
      if (Math.abs(+value) < 20) return theme.palette.warning.main;
      return theme.palette.error.main;
    },
    [
      color,
      active,
      simple,
      theme.palette.success.main,
      theme.palette.warning.main,
      theme.palette.error.main,
    ]
  );

  const data = useMemo(
    () => [
      {
        id: 1,
        value: filled ? 100 : Math.abs(+value),
        color: comparing ? "#ECECEC" : getColorKey(value),
      },
      {
        id: 2,
        value: filled ? 0 : Math.abs(100 - +value),
        color: comparing ? "rgba(255,255,255,.3)" : "#ececec",
      },
    ],
    [filled, value, comparing, getColorKey]
  );

  return (
    <Tooltip
      title={definitions[label.toLowerCase()] || ""}
      placement="top"
      arrow
    >
      <Stack alignItems="center" gap={1}>
        <Box height={dimension} width={dimension} position="relative">
          <Typography
            position="absolute"
            top="50%"
            left="50%"
            variant={typeVariant}
            sx={{
              transform: "translate3d(-50%, -50%, 0)",
              textAlign: "center",
            }}
            color={textColor}
          >
            {showPolarity && value > 0 ? "+" + value : value}
          </Typography>
          <ResponsivePie
            isInteractive={false}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            data={data}
            innerRadius={thickness}
            padAngle={0}
            colors={graphColor}
            fit
          />
        </Box>

        <Typography variant="caption" textTransform="capitalize">
          {label}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
