import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { makePrimaryCompare, removeItemAtIndex } from "../../../services/store";
import { useCallback, useMemo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { JudgesCardAside } from "../../components";
import { compareJudgesState } from "../../../services/store";
import { hotjar } from "react-hotjar";
import { useAtom } from "jotai";

const shouldDisplayValue = (value) => {
  if (typeof value === "number") return true;
  const unwantedValues = [
    "Unknown",
    "Unknown ()",
    "Unknown (9999)",
    "unknown",
    "unknown ()",
    "unknown (9999)",
    "N/A (9999)",
    "N/A",
    "n/a",
    "N/A ()",
    "",
  ];

  let v = "";

  if (typeof value === "string") {
    v = value == null ? "" : String(value).trim();
  }

  if (typeof value === "object") {
    if (value?.year && value?.rating) {
      v = `${value.rating} (${value.year})`;
    }
  }

  return v !== "" && !unwantedValues.includes(v);
};

export const JudgesCompareCard = ({
  active,
  activeTab,
  judge,
  primaryJudge,
}) => {
  const { id } = judge;
  const [items, updateItems] = useAtom(compareJudgesState);
  const index = items.findIndex((item) => item === id);
  const theme = useTheme();

  const donutData = useMemo(() => {
    let result = {};
    switch (activeTab) {
      case 0:
        result = active
          ? judge.jurisprudence
          : Object.keys(judge.jurisprudence || {}).reduce((acc, key) => {
              acc[key] =
                judge.jurisprudence[key] -
                (primaryJudge.jurisprudence[key] || 0);
              return acc;
            }, {});
        break;
      case 1:
        result = active
          ? judge.tone
          : Object.keys(judge.tone || {}).reduce((acc, key) => {
              acc[key] = judge.tone[key] - (primaryJudge.tone[key] || 0);
              return acc;
            }, {});
        break;
      case 2:
        result = active
          ? judge.sentiment
          : Object.keys(judge.sentiment || {}).reduce((acc, key) => {
              acc[key] =
                judge.sentiment[key] - (primaryJudge.sentiment[key] || 0);
              return acc;
            }, {});
        break;
      default:
        return {};
    }
    return result;
  }, [active, activeTab, judge, primaryJudge]);

  const deleteItem = useCallback(() => {
    const updated = removeItemAtIndex(items, index);
    updateItems(updated);
  }, [items, index, updateItems]);

  const makePrimary = useCallback(() => {
    const updated = makePrimaryCompare(items, id);
    updateItems(updated);
    hotjar.event("compare-judges-change-primary");
  }, [id, items, updateItems]);

  const filteredJudge = Object.entries(judge).reduce((acc, [key, value]) => {
    if (key === "jurisprudence") {
      acc[key] = Object.entries(value).reduce((jurAcc, [jurKey, jurValue]) => {
        if (shouldDisplayValue(jurValue)) {
          jurAcc[jurKey] = jurValue;
        }
        return jurAcc;
      }, {});
    } else if (shouldDisplayValue(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return (
    <Paper
      elevation={1}
      sx={{
        height: "100%",
        border: active ? `3px solid ${theme.palette.primary.main}` : "none",
      }}
    >
      <Stack
        backgroundColor="white"
        color={active ? "white" : null}
        height="100%"
        position="relative"
      >
        <Box position="absolute" top={3} right={3}>
          <IconButton onClick={deleteItem}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box flex={1} paddingTop={2}>
          {active ? (
            <JudgesCardAside
              simple
              compact
              isPrimary={true}
              active={active}
              filled={active ? false : true}
              showPolarity={active ? false : true}
              donutData={donutData}
              {...filteredJudge}
            />
          ) : (
            <JudgesCardAside
              simple
              compact
              active={active}
              filled
              showPolarity
              donutData={donutData}
              {...{
                ...filteredJudge,
                jurisprudence: {
                  textualist:
                    judge.jurisprudence.textualist -
                    primaryJudge.jurisprudence.textualist,
                  traditionalist:
                    judge.jurisprudence.traditionalist -
                    primaryJudge.jurisprudence.traditionalist,
                  precedent:
                    judge.jurisprudence.precedent -
                    primaryJudge.jurisprudence.precedent,
                  policy:
                    judge.jurisprudence.policy -
                    primaryJudge.jurisprudence.policy,
                  purposivist:
                    judge.jurisprudence.purposivist -
                    primaryJudge.jurisprudence.purposivist,
                  originalist:
                    judge.jurisprudence.originalist -
                    primaryJudge.jurisprudence.originalist,
                },
                sentiment: {
                  positive:
                    judge.sentiment.positive - primaryJudge.sentiment.positive,
                  negative:
                    judge.sentiment.negative - primaryJudge.sentiment.negative,
                  openness:
                    judge.sentiment.openness - primaryJudge.sentiment.openness,
                  obstinance:
                    judge.sentiment.obstinance -
                    primaryJudge.sentiment.obstinance,
                  empathy:
                    judge.sentiment.empathy - primaryJudge.sentiment.empathy,
                  detachment:
                    judge.sentiment.detachment -
                    primaryJudge.sentiment.detachment,
                },
                tone: {
                  agreeableness:
                    judge.tone.agreeableness - primaryJudge.tone.agreeableness,
                  antagonistic:
                    judge.tone.antagonistic - primaryJudge.tone.antagonistic,
                  formal: judge.tone.formal - primaryJudge.tone.formal,
                  informal: judge.tone.informal - primaryJudge.tone.informal,
                  eccentricity:
                    judge.tone.eccentricity - primaryJudge.tone.eccentricity,
                  stoicism: judge.tone.stoicism - primaryJudge.tone.stoicism,
                },
              }}
            />
          )}
        </Box>
        <Stack justifyContent="center" alignItems="center">
          {active ? (
            <Typography
              backgroundColor="primary.main"
              paddingY={1}
              width="100%"
              sx={{
                textAlign: "center",
              }}
            >
              Primary
            </Typography>
          ) : (
            <Button
              onClick={makePrimary}
              variant="contained"
              fullWidth
              data-cy="make-primary-btn-judges"
            >
              Make Primary
            </Button>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
