import { Link, Stack, Typography } from "@mui/material";

import parse from "html-react-parser";

export function HTMLParser({ heading, content, handleClicks }) {
  function replace(node, index) {
    if (handleClicks && node.type === "tag" && node.name === "a") {
      return (
        <Link
          href="#"
          key={`${node.name}-${index}`}
          onClick={handleClicks[node.children[0].data]}
        >
          {node.children[0].data}
        </Link>
      );
    }
  }

  return (
    <Typography component={Stack} sx={{ gap: 2 }} variant="body2">
      {parse(content, {
        replace,
      })}
    </Typography>
  );
}
