import { CircularProgress } from "@mui/material";
import { DeBriefCaseContextCards } from "./DeBriefCaseContextCards";
import { DeBriefCaseItem } from "./DeBriefCaseItem";
import { DeBriefCaseJudge } from "./DeBriefCaseJudge";
import Grid from "@mui/material/Unstable_Grid2";
import { Suspense } from "react";

export const DeBriefCaseContainer = ({
  cased,
  type,
  versionIndex,
  subjects,
}) => {
  return (
    <Grid alignItems="stretch" container columnSpacing={4} rowSpacing={2}>
      <Grid xs={12} sm={4}>
        <DeBriefCaseJudge {...cased.judge} type={type} active />
      </Grid>
      <Grid xs={12} sm={8}>
        <DeBriefCaseItem {...cased} type={type} versionIndex={versionIndex} />
      </Grid>
      <Grid xs={12}>
        <Suspense loading={<CircularProgress color="inherit" size={30} />}>
          <DeBriefCaseContextCards
            {...cased}
            type={type}
            versionIndex={versionIndex}
            subjects={subjects}
          />
        </Suspense>
      </Grid>
    </Grid>
  );
};
