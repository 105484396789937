import { fetchAuthSession } from "aws-amplify/auth";

const base = async ({ path, options }) => {
  const session = await fetchAuthSession();
  const token = session.tokens.idToken.toString();
  const sessionId = session.tokens.accessToken.payload.event_id;

  try {
    const headers = {};

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
      headers["Content-Type"] = "application/json";
      headers["X-Rhetoric-Session-ID"] = sessionId;
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${path}`,
      {
        headers,
        ...options,
      }
    );

    const json = await response.json();

    if (json?.message) {
      throw new Error(json?.message);
    }

    return json;
  } catch (error) {
    throw error;
  }
};

export const get = async (path, options = {}) => {
  return base({ path, options: { method: "GET", ...options } });
};

export const post = async (path, options = {}) => {
  return base({ path, options: { method: "POST", ...options } });
};

export const patch = async (path, options = {}) => {
  return base({ path, options: { method: "PATCH", ...options } });
};

export const deleted = async (path, options = {}) => {
  return base({ path, options: { method: "DELETE", ...options } });
};

export const file = async (url, options = {}) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      ...options,
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return response;
  } catch (error) {
    throw error;
  }
};
