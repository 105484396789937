import { color, colorsKey2 } from "../../views/DeBriefCase/colors";

import { ScoreRange } from "../ScoreRange";
import { Stack } from "@mui/material";
import { StatPieGraphText } from "../StatPieGraphText";
import { Typography } from "@mui/material";

export const DeBriefCardSummary = ({
  rhetoric_juris_score,
  rhetoric_sentiment_score,
  rhetoric_tone_score,
  rhetoric_score,
  rhetoric_grade,
  onClick,
}) => (
  <Stack
    sx={{
      cursor: "pointer",
      flexDirection: ["column", "column", "row"],
      gap: 4,
    }}
    onClick={onClick}
  >
    <Stack sx={{ flex: 1 }}>
      <StatPieGraphText
        color={color(rhetoric_score)}
        value={rhetoric_score}
        comparing={false}
        dimension={225}
        thickness={0.8}
        typeVariant="display"
      />
      <Typography variant="h4" sx={{ textAlign: "center", marginTop: 1 }}>
        {`Rhetoric Score: ${rhetoric_grade}`}
      </Typography>
    </Stack>
    <Stack sx={{ flex: 2, gap: 3, justifyContent: "center" }}>
      <ScoreRange
        label="Jurisprudence"
        color={color(rhetoric_juris_score, colorsKey2)}
        value={rhetoric_juris_score}
        onClick={onClick}
      />
      <ScoreRange
        label="Sentiment"
        color={color(rhetoric_sentiment_score, colorsKey2)}
        value={rhetoric_sentiment_score}
        onClick={onClick}
      />
      <ScoreRange
        label="Tone"
        color={color(rhetoric_tone_score, colorsKey2)}
        value={rhetoric_tone_score}
        onClick={onClick}
      />
    </Stack>
  </Stack>
);
