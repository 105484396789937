import { IconButton, TextField as Input, InputAdornment } from "@mui/material";
import { useCallback, useState } from "react";

import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const TextField = ({
  name,
  label,
  type,
  helperText,
  placeholder,
  control,
  defaultValue,
  rules,
  multiline = false,
  trim = true,
  errors,
  maxRows,
  inputProps,
  size = "medium",
  disabled = false,
  handleOnChange = () => null,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );
  const handleMouseDownPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Input
          {...field}
          label={label}
          type={showPassword ? "text" : type}
          helperText={helperText}
          error={errors}
          placeholder={placeholder}
          fullWidth
          maxRows={maxRows}
          multiline={multiline}
          size={size}
          disabled={disabled}
          onChange={(event) => {
            if (trim) {
              const value = event.target.value;
              event.target.value = value.trim();
            }
            field.onChange(event);
            handleOnChange(event);
          }}
          inputProps={{
            "data-cy": `text-field-${name}`,
            ...inputProps,
          }}
          InputProps={{
            endAdornment: type === "password" && (
              <InputProps
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                showPassword={showPassword}
              />
            ),
          }}
        />
      )}
    />
  );
};

const InputProps = ({
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      onMouseDown={handleMouseDownPassword}
    >
      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </IconButton>
  </InputAdornment>
);
