import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { jurisdictions, states } from "../../../services/store/data";

import CloseIcon from "@mui/icons-material/Close";
import { drawerDoctrinaSearchState } from "../../../services/store";
import { hotjar } from "react-hotjar";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSetAtom } from "jotai";

export const defaultDoctrinaValues = {
  jurisdictions: [],
  state: [],
};

export const SearchDoctrina = () => {
  const setFilterDrawerOpen = useSetAtom(drawerDoctrinaSearchState);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { ...defaultDoctrinaValues },
  });

  const values = getValues();

  const handleCloseDrawer = () => setFilterDrawerOpen(false);

  useEffect(() => {
    searchParams.forEach((value, key) => {
      setValue(
        key,
        value.split(",").filter((v) => v)
      );
    });
  }, [searchParams, setValue]);

  useEffect(() => {
    if (!searchParams.toString()) {
      reset();
    }
  }, [searchParams, reset]);

  const onSubmit = async (data) => {
    const queryParams = {};

    if (data.state && data.state.length > 0) {
      queryParams.state = data.state.join(",");
    }

    if (data.jurisdictions && data.jurisdictions.length > 0) {
      queryParams.jurisdictions = data.jurisdictions.join(",");
    }

    setSearchParams(queryParams);
    handleCloseDrawer();
    hotjar.event("search-courts-submit");
  };

  const handleResetJurisdictions = useCallback(() => {
    reset({ ...values, jurisdictions: [] });
  }, [reset, values]);

  const handleResetStates = useCallback(() => {
    reset({ ...values, state: [] });
  }, [reset, values]);

  const handleResetAll = () => {
    reset(defaultDoctrinaValues);
    setSearchParams({});
  };

  return (
    <Box position="relative">
      <IconButton
        onClick={handleCloseDrawer}
        sx={{ position: "absolute", top: 16, right: 16 }}
      >
        <CloseIcon />
      </IconButton>
      <Stack paddingTop={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box paddingX={3} mb={2}>
            <Typography variant="h3">Filter Courts</Typography>
          </Box>
          <Stack
            sx={{
              flexDirection: ["column"],
              alignItems: "flex-start",
              pb: 5,
            }}
            gap={3}
          >
            <Stack
              sx={{
                flex: 1,
                paddingX: 3,
                paddingTop: 1,
                width: ["100%", "100%", "auto"],
              }}
            >
              <Stack direction="row">
                <Typography sx={{ paddingBottom: 1 }}>Jurisdictions</Typography>
              </Stack>
              <Controller
                name="jurisdictions"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={jurisdictions}
                    getOptionLabel={(option) => option.label}
                    value={
                      field.value
                        ? jurisdictions.filter((jurisdiction) =>
                            field.value.includes(jurisdiction.id)
                          )
                        : []
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue.map((item) => item.id));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select jurisdictions"
                        placeholder="Add jurisdictions"
                        variant="outlined"
                        sx={{ width: 300, paddingBottom: 1 }}
                      />
                    )}
                  />
                )}
              />
              <Box paddingTop={1}>
                <Button
                  onClick={handleResetJurisdictions}
                  variant="outlined"
                  size="small"
                >
                  Reset Jurisdictions
                </Button>
              </Box>
            </Stack>
            <Stack
              sx={{
                flex: 1,
                paddingX: 3,
                paddingTop: 1,
                width: ["100%", "100%", "auto"],
              }}
            >
              <FormControl fullWidth>
                <Stack direction="row">
                  <Typography sx={{ paddingBottom: 1 }}>States</Typography>
                </Stack>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      options={states}
                      getOptionLabel={(option) => option.name}
                      value={
                        field.value
                          ? states.filter((state) =>
                              field.value.includes(state.abbreviation)
                            )
                          : []
                      }
                      onChange={(event, newValue) => {
                        field.onChange(
                          newValue.map((item) => item.abbreviation)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select states"
                          placeholder="Add states"
                          variant="outlined"
                          sx={{ width: 300, paddingBottom: 1 }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              <Box paddingTop={1}>
                <Button
                  onClick={handleResetStates}
                  variant="outlined"
                  size="small"
                >
                  Reset States
                </Button>
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent={["flex-end", "flex-end", "space-between"]}
            paddingX={3}
            paddingY={3}
            position="sticky"
            backgroundColor="white"
            boxShadow="0 -5px 10px rgba(0,0,0,.05)"
            bottom={0}
            zIndex={1}
          >
            <Button
              variant="outlined"
              onClick={handleResetAll}
              disabled={isSubmitting}
              sx={{ marginRight: 2 }}
            >
              Reset Filters
            </Button>
            <Button disabled={isSubmitting} variant="contained" type="submit">
              Submit Search
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
