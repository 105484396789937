import {
  Box,
  Slider,
  SliderThumb,
  Stack,
  Typography,
  styled,
} from "@mui/material";

import { memo } from "react";

export const ScoreRange = memo(
  ({ label = "label", value = 50, color = "primary", onClick }) => {
    return (
      <Stack sx={{ alignItems: "center", flexDirection: "row", gap: 4 }}>
        <Box sx={{ flex: 1, mt: -1.5 }}>
          <Typography variant="h4" sx={{ textAlign: "right" }}>
            {label}
          </Typography>
        </Box>
        <Box sx={{ flex: 2 }} onClick={onClick}>
          <MuiSlider
            sx={{ color }}
            defaultValue={value}
            value={value}
            slots={{
              thumb: (props) => <MuiThumb value={value} {...props} />,
            }}
            onMouseDown={(e) => e.preventDefault()}
          />
        </Box>
      </Stack>
    );
  }
);

const MuiSlider = styled(Slider)(({ theme }) => ({
  "&.MuiSlider-root": {
    cursor: "default",
    pointerEvents: "none",
  },
  "& .MuiSlider-thumb": {
    height: 40,
    width: 40,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "none",
      cursor: "default",
    },
  },
  "& .MuiSlider-track": {
    height: 15,
    "&:focus, &:hover, &.Mui-active": {
      cursor: "default",
    },
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#bfbfbf",
    height: 15,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "none",
      cursor: "default",
    },
  },

  ".MuiSlider-thumb:not(.MuiSlider-active)": {
    transition: "left 1s ease-in",
  },

  ".MuiSlider-track": {
    transition: "width 1s ease-in",
  },
}));

const MuiThumb = ({ value, ...other }) => (
  <SliderThumb
    {...other}
    sx={{
      background: "none",
      marginLeft: 2,
      "&:before": { display: "none" },
    }}
  >
    <Typography variant="caption" color="#000">
      {value}
    </Typography>
  </SliderThumb>
);
