import * as api from "../../../services/api";

import { Box, Typography } from "@mui/material";

import { JudgesCard } from "../../components";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect } from "react";

export const JudgeContainer = ({ id, subjects, setIsJudgeX }) => {
  const params = decodeURIComponent(
    new URLSearchParams({
      subject: subjects.map((subject) => subject.id).join(","),
    }).toString()
  );

  const { data: judge, error } = useSuspenseQuery({
    queryKey: ["judges", id, params],
    queryFn: async () => {
      return await api.get(
        `/judges/${id}${subjects?.length ? `?${params}` : ""}`
      );
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (judge) {
      if (judge.is_judge_x !== undefined) {
        setIsJudgeX(judge.is_judge_x);
      }
    }
  }, [judge, setIsJudgeX]);

  if (error) {
    return <Typography>Error fetching data: {error.message}</Typography>;
  }

  if (!judge) {
    return (
      <Box paddingTop={4} paddingX={4}>
        <Typography>No data found</Typography>
      </Box>
    );
  }

  return <JudgesCard {...judge} isDetailPage />;
};
