import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";

import { DebriefUploadStep } from "../DeBriefUploadStep";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { SelectField } from "../SelectField";
import { TextField } from "../TextField";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { hotjar } from "react-hotjar";
import { uploadModalAtom } from "../../../services/store";
import { useAtom } from "jotai";
import { useCreateBrief } from "../../../services/queries";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useUploadBriefFile } from "../../../services/models";

export const documentTypeOptions = [
  {
    value: "Brief",
    label: "Brief",
  },
  {
    value: "Motion",
    label: "Motion",
  },
  {
    value: "Oral Argument",
    label: "Oral Argument",
  },
  {
    value: "Document",
    label: "Document (Other)",
  },
];

export const DeBriefUploadModal = ({
  brief_id,
  judgeName,
  caseName = "",
  clientNumber = "",
  matterNumber = "",
  documentType = documentTypeOptions[0].value,
}) => {
  const [, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [_files, setFiles] = useState([]);
  const [current, setCurrent] = useState(1);
  const [open, setModalOpen] = useAtom(uploadModalAtom(brief_id));

  const defaultValues = {
    case_name: caseName,
    client_number: clientNumber,
    matter_number: matterNumber,
    document_type: documentType,
  };

  const { control, watch } = useForm({
    defaultValues,
  });

  const case_name = watch("case_name");
  const client_number = watch("client_number");
  const matter_number = watch("matter_number");
  const document_type = watch("document_type");

  const { mutateAsync, isPending } = useCreateBrief({
    newVersionUpload: true,
  });

  const onDrop = useCallback((files) => {
    setFiles(files);
    setCurrent(2);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open: openFileDialogue,
  } = useUploadBriefFile({ onDrop });

  const handleClose = useCallback(() => {
    setCurrent(1);
    setModalOpen(false);
  }, [setModalOpen]);

  const handleMutateAsync = async () => {
    await mutateAsync(
      {
        brief_id,
        files: _files,
        case_name,
        client_number,
        matter_number,
        document_type,
      },
      {
        onSuccess: (data) => {
          hotjar.event("debrief-new-version-upload");
          setSearchParams({});
          queryClient.invalidateQueries({
            queryKey: ["brief", data.brief_id, [], []],
          });
          queryClient.setQueryData(
            ["brief", data.brief_id, [], []],
            () => data.case
          );
        },
      }
    );

    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <Box maxWidth={900} width="80%" position="relative">
          {current !== 1 && isPending && (
            <Stack
              alignItems="center"
              justifyContent="center"
              backgroundColor="rgba(255,255,255,.98)"
              position="absolute"
              height="100%"
              width="100%"
              top={0}
              left={0}
              zIndex={3}
            >
              <img src="/gifs/loading-brief.gif" alt="loading" width={250} />
              {judgeName && (
                <Typography textAlign="center" variant="body1">
                  Your document is being uploaded and will be analyzed with{" "}
                  <strong>{judgeName}</strong>
                </Typography>
              )}
            </Stack>
          )}
          <Paper elevation={4}>
            <Stack
              paddingX={4}
              paddingY={3}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3">Upload new version</Typography>
              <Button onClick={handleClose}>Close</Button>
            </Stack>
            <Box maxWidth="100%" padding={4}>
              <Stack direction="row" justifyContent="center">
                <Box position="relative" zIndex={2}>
                  <DebriefUploadStep
                    step={1}
                    icon={UploadFileIcon}
                    heading="Upload Brief"
                    current={current === 1}
                    completed={current > 1}
                  />
                </Box>
                <Box
                  flexGrow={1}
                  height={3}
                  backgroundColor="grey.200"
                  marginX={[0, -4]}
                  position="relative"
                  top={[25, 150]}
                  maxWidth={300}
                  zIndex={1}
                />
                <Box position="relative" zIndex={1}>
                  <DebriefUploadStep
                    step={2}
                    icon={PublishedWithChangesIcon}
                    heading="Process"
                    current={current === 2}
                    completed={current > 2}
                  />
                </Box>
              </Stack>
            </Box>
            <Divider />
            <Box padding={4}>
              {current === 1 && (
                <Stack direction={["column-reverse", "row"]} gap={4}>
                  <Typography variant="body2" color="text.light">
                    Press the “Select Document” button beside and select the
                    word or readable PDF document you would like for us to
                    analyze.
                  </Typography>
                  <Box flexGrow={1} flexBasis="50%">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        onClick={openFileDialogue}
                        type="button"
                        size="large"
                        variant="contained"
                      >
                        Select Document
                      </Button>
                    </div>
                  </Box>
                </Stack>
              )}
              {current === 2 && (
                <Stack sx={{ gap: 4 }}>
                  <Stack sx={{ flexDirection: "row", gap: 2 }}>
                    <TextField
                      control={control}
                      placeholder="Case Name"
                      label="Case Name"
                      fullWidth
                      name="case_name"
                      trim={false}
                      size="small"
                      helperText="This field is not required"
                    />
                    <TextField
                      control={control}
                      placeholder="Client Number"
                      label="Client Number"
                      fullWidth
                      name="client_number"
                      trim={false}
                      size="small"
                      helperText="This field is not required"
                    />
                    <TextField
                      control={control}
                      placeholder="Matter Number"
                      label="Matter Number"
                      fullWidth
                      name="matter_number"
                      trim={false}
                      size="small"
                      helperText="This field is not required"
                    />
                    <SelectField
                      label="Document Type"
                      placeholder="Document Type"
                      name="document_type"
                      control={control}
                      options={documentTypeOptions}
                      size="small"
                    />
                  </Stack>
                  <Stack direction="row" spacing={4}>
                    <Typography variant="body2" color="text.light" flexGrow={1}>
                      DeBrief is processing the document you uploaded, which may
                      take up to 60 seconds.
                    </Typography>
                    <Box flexGrow={1} flexBasis="50%">
                      <Button
                        onClick={handleMutateAsync}
                        type="button"
                        size="large"
                        variant="contained"
                      >
                        {isPending ? (
                          <CircularProgress color="inherit" size={30} />
                        ) : (
                          "Process"
                        )}
                      </Button>
                    </Box>
                  </Stack>
                </Stack>
              )}
            </Box>
          </Paper>
        </Box>
      </Stack>
    </Modal>
  );
};
