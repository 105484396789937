import { Tooltip, Typography } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material/styles";

export const AggregateTag = ({ sx, tooltipText }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={
        tooltipText ||
        "This is an aggregate court, which means the data for this court cannot be attributed to individual judges."
      }
      placement="top"
    >
      <Typography
        variant="body2"
        sx={{
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.common.black,
          borderRadius: 1,
          padding: "0.5rem",
          gap: 1,
          marginTop: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          cursor: "default",
          ...sx,
        }}
      >
        <InfoIcon
          color="secondary"
          sx={{
            width: 15,
            height: 15,
          }}
        />
        Aggregate Sample
      </Typography>
    </Tooltip>
  );
};
