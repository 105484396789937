import { Button, FormHelperText, Link, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { TextField } from "../../components";
import { useAuth } from "../../../services/auth";
import { useForm } from "react-hook-form";

const defaultValues = {
  email: "",
  password: "",
  confirm: "",
};

export const RegisterForm = () => {
  const password = useRef({});
  const [success, setSuccess] = useState(false);
  const [genericError, setGenericError] = useState("");

  const {
    control,
    handleSubmit,
    setFocus,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  password.current = watch("password", "");

  const { handleRegistration } = useAuth();

  const onSubmit = async (data) => {
    try {
      setGenericError("");
      const { status } = await handleRegistration(data.email, data.password);

      if (status === "success") {
        setSuccess(true);
      }
    } catch (error) {
      setGenericError(error.message);
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  if (success) {
    return (
      <Stack>
        <Typography variant="h3">Registration successful.</Typography>
        <Typography>
          You can now <Link href="/login">login</Link>
        </Typography>
      </Stack>
    );
  }

  return (
    <form data-cy="form-login" onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField
          label="Email"
          helperText={errors?.email?.message}
          name="email"
          control={control}
          rules={{ required: "A valid email is required." }}
        />
        <TextField
          label="Password"
          type="password"
          name="password"
          helperText={errors?.password?.message}
          control={control}
          rules={{ required: "Your password is required." }}
        />
        <TextField
          label="Confirm password"
          type="password"
          name="confirm"
          helperText={errors?.confirm?.message}
          control={control}
          rules={{
            required: "Please confirm your password.",
            validate: (value) =>
              value === password.current || "The passwords do not match",
          }}
        />
        <Button
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          size="large"
          data-cy="form-login-submit"
        >
          Submit Registration
        </Button>
        {genericError && <FormHelperText error>{genericError}</FormHelperText>}
      </Stack>
    </form>
  );
};
