import { Box, Button, Slider, Stack, Typography } from "@mui/material";
import { Checkbox, SelectField } from "../../components";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { PaymentTiers } from "./PaymentTiers";
import { createDemoBooking } from "../../../services/airtable";
import { useSuspenseQuery } from "@tanstack/react-query";
import { userOptions } from "../../../services/queries";

const yearsOptions = [
  {
    value: "1 Year",
    label: "1 Year",
    number: 1,
  },
  {
    value: "2 Years",
    label: "2 Years (10% Discount)",
    number: 2,
  },
  {
    value: "4 Years",
    label: "4 Years (25% Discount)",
    number: 3,
  },
];

const tiersNormal = [
  {
    value: "Basic",
    name: "Basic",
    amount: 300,
    options: [
      "Doctrina",
      "Judges",
      "DeBrief",
      "All Appeals Courts/Judges",
      "Jurisprudence Analysis",
    ],
  },
  {
    value: "Premium",
    name: "Premium",
    amount: 600,
    options: ["Basic Options", "Sentiment Analysis", "Unlimited Brief Uploads"],
  },
  {
    value: "Enterprise",
    name: "Enterprise",
    amount: 1200,
    options: [
      "Premium Options",
      "Composite Court/Judge Analysis",
      "24/7 Customer Support",
      "Custom Judge Analysis*",
    ],
  },
  {
    value: "Enterprise+",
    name: "Enterprise+",
    amount: "Contact Us",
    options: [
      "Enterprise Options",
      "On-Site Training",
      "All Future Modules Included ",
      "Custom Judge Analysis",
    ],
  },
];

const tiers = tiersNormal;

const defaultValues = {
  seats: 1,
  years: yearsOptions[0].value,
  tier: tiers[0].value,
  federal_trial_court_data: false,
  state_appellate_court_data: false,
};

const seatDiscountCalculate = (seats) => {
  if (seats >= 105) {
    return 0.2;
  }

  if (seats >= 75) {
    return 0.15;
  }

  if (seats >= 50) {
    return 0.1;
  }

  if (seats >= 25) {
    return 0.05;
  }

  return 0;
};

const yearDiscountCalculate = (year) => {
  if (year === "4 Years") {
    return 0.25;
  }

  if (year === "2 Years") {
    return 0.1;
  }

  return 0;
};

const textDiscountCalculate = (seats, year) => {
  let yearText = ``;
  let seatsText = ``;

  if (year === "4 Years") {
    yearText = `25% Multi-Year Discount`;
  } else if (year === "2 Years") {
    yearText = `10% Multi-Year Discount`;
  }

  if (seats >= 105) {
    seatsText = `20% User Discount`;
  } else if (seats >= 75) {
    seatsText = `15% User Discount`;
  } else if (seats >= 50) {
    seatsText = `10% User Discount`;
  } else if (seats >= 25) {
    seatsText = `5% User Discount`;
  }

  return `${seatsText}${seatsText && yearText && "; "}${yearText}`;
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const Success = () => (
  <Stack alignItems="center">
    <Typography variant="h1">Thank you!</Typography>
    <Typography>Rhetoric will be in contact with you shortly.</Typography>
  </Stack>
);

export const PaymentModalForm = () => {
  const { data: user } = useSuspenseQuery(userOptions());
  const [total, setTotal] = useState(
    defaultValues.seats * 12 * tiers[0].amount
  );
  const [discountText, seatDiscountText] = useState(``);
  const [success, setSuccess] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const seats = watch("seats");
  const tier = watch("tier");

  const onSubmit = (data) => {
    createDemoBooking({
      fields: {
        Email: user.email,
        Tier: data.tier,
        "Number of Seats": data.seats,
        Years: data.years,
        "Early Access Federal Trial Court Data": data.federal_trial_court_data,
        "Early Access Appellate Court Data": data.state_appellate_court_data,
      },
    });

    setSuccess(true);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const tier = tiers.find((item) => item.name === value.tier);
      const subtotal = tier.amount * value.seats;
      const seatDiscount = subtotal * seatDiscountCalculate(value.seats);
      const yearDiscount = subtotal * yearDiscountCalculate(value.years);
      const total = subtotal - seatDiscount - yearDiscount;

      const years = yearsOptions.find((item) => item.value === value.years);

      seatDiscountText(textDiscountCalculate(value.seats, value.years));

      setTotal(total * 12 * years.number);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  if (success) return <Success />;

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={3}>
      <Stack>
        <Typography variant="h2">
          {tier === tiers[tiers.length - 1].name
            ? "Contact Us for Pricing"
            : `Total: ${formatter.format(total)}`}
        </Typography>
        <Typography variant="body2" color="green">
          {discountText}
        </Typography>
      </Stack>
      <Box>
        <PaymentTiers tier={tier} control={control} tiers={tiers} />
      </Box>
      <Stack sx={{ flexDirection: ["column", "row"], gap: [2, 5] }}>
        <Stack sx={{ flex: 1 }}>
          <Typography variant="caption">Seats: {seats}</Typography>
          <Controller
            name="seats"
            control={control}
            render={({ field }) => (
              <Slider
                {...field}
                step={5}
                marks
                min={0}
                max={200}
                valueLabelDisplay="auto"
              />
            )}
          />
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <Typography>Years</Typography>
          <SelectField
            select
            helperText={errors?.license_holder?.message}
            name="years"
            control={control}
            rules={{ required: "Please choose one." }}
            options={yearsOptions}
          />
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography variant="h3">Add-ons</Typography>
        <Checkbox
          control={control}
          name="federal_trial_court_data"
          label="Early access to federal trial court data"
        />
        <Checkbox
          control={control}
          name="state_appellate_court_data"
          label="Early access to state appellate court data"
        />
      </Stack>
      <Button variant="contained" size="large" type="submit">
        Submit
      </Button>
    </Stack>
  );
};
