import { Divider, Stack, Typography } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import { StatPieGraphText } from "../StatPieGraphText";

export const DoctrinaCardAside = ({
  active = false,
  filled = false,
  showPolarity = false,
  jurisprudence,
  donutData,
  name,
  type,
  judges_count,
  court_opinions_count,
  political_affiliations,
}) => (
  <Stack padding={3} spacing={3} backgroundColor="white" color="text.main">
    <Stack spacing={1}>
      <Typography variant="h3">{name}</Typography>
      <Typography variant="h4">{type}</Typography>
    </Stack>
    <Stack>
      <Typography>{judges_count} Judges</Typography>
      <Typography>{court_opinions_count} Opinions</Typography>
    </Stack>
    <Grid container>
      {donutData &&
        Object.keys(donutData)?.length > 0 &&
        Object.keys(donutData)?.map((key) => (
          <Grid xs={6} key={key}>
            <StatPieGraphText
              filled={active ? false : true}
              showPolarity={showPolarity}
              label={key}
              active={active}
              value={donutData[key]}
              comparing={active}
            />
          </Grid>
        ))}
    </Grid>
    <Divider />
    <Typography>Court Demographics</Typography>
    <Grid container>
      {Object.keys(political_affiliations).map((key) => (
        <Grid xs={6} key={key}>
          <StatPieGraphText
            comparing={active}
            label={key}
            value={political_affiliations[key]}
            filled={active ? false : true}
            showPolarity={showPolarity}
            active={active}
          />
        </Grid>
      ))}
    </Grid>
  </Stack>
);
