import { Link } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const MobileNavigation = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      navigate("/");
    },
    [navigate]
  );

  return (
    <>
      <Link href="/" variant="body2" onClick={handleClick}>
        Menu
      </Link>
    </>
  );
};
