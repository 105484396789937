import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  CircularProgress,
  Divider,
  Autocomplete,
  TextField as MuiTextField,
} from "@mui/material";
import { Default as Layout } from "../../layouts";
import Grid from "@mui/material/Unstable_Grid2";
import { CiceroCreateSteps } from "./CiceroCreateSteps";
import { useForm, useController } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import * as api from "../../../services/api";
import { useCompletedAssignments } from "../Cicero/Cicero";
import { TextField } from "../../components";

const defaultValues = {
  case_name: "",
  client_number: "",
  matter_number: "",
  activity_title: "",
};

const descriptions = {
  "Oral Argument (General)":
    "Practice your oral argument and receive tailored feedback. Some key criteria for a compelling oral argument are ensuring a proper argument structure, exhibiting proper etiquette and style, demonstrating confidence and adaptability, crafting powerful conclusions, and effectively addressing opposing arguments.",
  "Cold Calls 1":
    "You will be prompted with 20 questions that simulate a law school classroom using the Socratic method and will record an answer for each one. Project confidence throughout the presentation, when faced with difficult questions. Lead with strong points to establish a confident tone and transition smoothly between questions and responses while addressing the major points. When finished, we will provide analysis and feedback on how you did and how you can improve.",
  "Hotbench Demo":
    "(DEMO) You will be prompted with 10 questions that simulate a judge asking questions in an oral argument setting. You will be given a series of questions and will record an answer for each one. When finished, we will provide analysis and feedback on how you did and how you can improve.",
  "Opening Statements":
    "Perform your opening statement and receive tailored feedback. Some key criteria for a compelling opening statement include constructing a coherent theory of the case, establishing a consistent theme throughout, utilizing foreshadowing, demonstrating emotional appeal, and clarifying legal concepts and the burden of proof.",
};

const Template = ({
  text,
  handleOnClick,
  buttonText,
  buttonDisabled = false,
  processing = false,
}) => (
  <Stack direction={["column-reverse", "row"]} gap={3}>
    <Typography flex={1} color="grey.700">
      {text}
    </Typography>
    <Button
      size="large"
      type="button"
      variant="contained"
      onClick={handleOnClick}
      disabled={buttonDisabled}
    >
      {processing ? <CircularProgress color="inherit" size={30} /> : buttonText}
    </Button>
  </Stack>
);

export const CiceroCreate = () => {
  const [step, setStep] = useState(1);
  const { control, handleSubmit, watch } = useForm({ defaultValues });
  const navigate = useNavigate();
  const { refetch: refetchCompletedAssignments } = useCompletedAssignments();

  const { data: coursesData, error: coursesError } = useQuery({
    queryKey: ["availableCourses"],
    queryFn: () => api.get("/cicero/list_available_courses"),
  });

  const activityTitleOptions = useMemo(() => {
    if (coursesData) {
      return coursesData.map((course) => ({
        value: course.activity_id,
        label: course.activity_title,
      }));
    }
    return [];
  }, [coursesData]);

  const createCiceroMutation = useMutation({
    mutationFn: (data) => api.post("/cicero", { body: JSON.stringify(data) }),
    onSuccess: (response) => {
      refetchCompletedAssignments();
      const resourceLinkId = response.params?.resource_link_id;
      navigate(`/cicero/case/${resourceLinkId}`);
    },
    onError: (error) => {
      console.error("Error creating Cicero assignment:", error);
    },
  });

  const handleFinish = handleSubmit((formData) => {
    const selectedCourse = coursesData.find(
      (course) => course.activity_id === formData.activity_title
    );

    const postData = {
      case_name: formData.case_name,
      client_number: formData.client_number,
      document_type: selectedCourse.activity_title,
      activity_title: selectedCourse.activity_title,
      matter_number: formData.matter_number,
      context_id: selectedCourse.context_id,
      context_title: selectedCourse.context_title,
      activity_id: selectedCourse.activity_id,
    };

    createCiceroMutation.mutate(postData);
  });

  const renderStep = () => {
    switch (step) {
      case 1:
        return <CaseDetails control={control} onNext={() => setStep(2)} />;
      case 2:
        return (
          <Template
            text="Use the search bar on the left side of the screen to select the recording type."
            buttonText="Review Details"
            handleOnClick={() => setStep(3)}
            buttonDisabled={!watch("activity_title")}
          />
        );
      case 3:
        return (
          <BeginRecording
            control={control}
            onFinish={handleFinish}
            options={activityTitleOptions}
            isLoading={createCiceroMutation.isPending}
          />
        );
      default:
        return null;
    }
  };

  if (coursesError) {
    return (
      <Typography color="error">
        Error loading courses: {coursesError.message}
      </Typography>
    );
  }

  if (createCiceroMutation.isPending) {
    return (
      <Layout>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          backgroundColor="rgba(255,255,255,.98)"
        >
          <img src="/gifs/loading-brief.gif" alt="loading" width={250} />
          <Typography textAlign="center" variant="body1">
            Your new recording is being created. Please wait...
          </Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box backgroundColor="grey.100" padding={4}>
        <Grid container spacing={2}>
          {step === 2 && (
            <Grid xs={12} md={3}>
              <SelectRecordingType
                control={control}
                options={activityTitleOptions}
              />
            </Grid>
          )}
          <Grid xs={12} md={step === 2 ? 9 : 12}>
            <Paper elevation={3}>
              <Box padding={8}>
                <Stack spacing={6}>
                  <CiceroCreateSteps current={step} />
                  <Divider />
                  {renderStep()}
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

const CaseDetails = ({ control, onNext }) => {
  return (
    <Stack spacing={4}>
      <Stack sx={{ flexDirection: "row", gap: 2 }}>
        <TextField
          control={control}
          name="case_name"
          label="Case Name"
          fullWidth
          size="small"
          helperText="This field is not required"
          trim={false}
        />
        <TextField
          control={control}
          name="client_number"
          label="Client Number"
          fullWidth
          size="small"
          helperText="This field is not required"
          trim={false}
        />
        <TextField
          control={control}
          name="matter_number"
          label="Matter Number"
          fullWidth
          size="small"
          helperText="This field is not required"
          trim={false}
        />
      </Stack>
      <Template
        text="Enter the case details above. These fields are optional but help organize your recordings."
        buttonText="Select Recording Type"
        handleOnClick={onNext}
      />
    </Stack>
  );
};

const SelectRecordingType = ({ control, options }) => {
  const { field } = useController({
    name: "activity_title",
    control,
  });

  const [selectedDescription, setSelectedDescription] = useState("");

  return (
    <Stack spacing={4}>
      <Typography variant="body2" color="grey.800">
        Select the recording type from the options below.
      </Typography>
      <Autocomplete
        value={options.find((option) => option.value === field.value) || null}
        onChange={(_, newValue) => {
          field.onChange(newValue ? newValue.value : null);
          setSelectedDescription(descriptions[newValue?.label] || "");
        }}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label="Select Recording Type"
            placeholder="Recording Type"
          />
        )}
        fullWidth
        size="small"
      />
      <Typography variant="body2" color="text.secondary">
        {selectedDescription ||
          "Select a recording type to see its description."}
      </Typography>
    </Stack>
  );
};

const BeginRecording = ({ control, onFinish, options, isLoading }) => {
  const { field: activityTitleField } = useController({
    name: "activity_title",
    control,
  });

  return (
    <Stack spacing={4}>
      <Stack sx={{ flexDirection: "row", gap: 2 }}>
        <TextField
          control={control}
          name="case_name"
          label="Case Name"
          fullWidth
          size="small"
          trim={false}
        />
        <TextField
          control={control}
          name="client_number"
          label="Client Number"
          fullWidth
          size="small"
          trim={false}
        />
        <TextField
          control={control}
          name="matter_number"
          label="Matter Number"
          fullWidth
          size="small"
          trim={false}
        />

        <Autocomplete
          value={
            options.find(
              (option) => option.value === activityTitleField.value
            ) || null
          }
          onChange={(_, newValue) =>
            activityTitleField.onChange(newValue ? newValue.value : null)
          }
          options={options}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <MuiTextField {...params} />}
          label="Recording Type"
          placeholder="Select Recording Type"
          fullWidth
          size="small"
          required
        />
      </Stack>
      <Template
        text="Review and edit details if needed. Click 'Begin Recording' when ready."
        buttonText="Begin Recording"
        handleOnClick={onFinish}
        buttonDisabled={!activityTitleField.value}
        processing={isLoading}
      />
    </Stack>
  );
};

export default CiceroCreate;
