import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import { Controller } from "react-hook-form";
import { Fragment } from "react";

export const PaymentTiers = ({ control, tiers, tier }) => {
  return (
    <Controller
      control={control}
      name="tier"
      render={({ field }) => (
        <RadioGroup defaultValue="Premium" orientation="vertical" {...field}>
          <List
            sx={{ display: "flex", gap: 1, flexDirection: ["column", "row"] }}
            variant="outlined"
          >
            {tiers.map((item, index) => (
              <Fragment key={item.name}>
                <ListItem
                  variant="outlined"
                  sx={{
                    alignSelf: "stretch",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor:
                      index === tiers.length - 1 ? "grey.500" : "grey.200",
                    p: 0,
                  }}
                  orientation="horizontal"
                >
                  <Stack
                    sx={{
                      height: "100%",
                      gap: 2,
                      py: 1,
                      width: "100%",
                    }}
                  >
                    <Box sx={{ position: "absolute", left: -999999 }}>
                      <Radio
                        id={item.name}
                        value={item.name}
                        label={item.name}
                      />
                    </Box>
                    <Box
                      sx={{
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        borderBottomColor:
                          index === tiers.length - 1 ? "grey.500" : "grey.200",
                        pb: 1,
                        width: "100%",
                      }}
                    >
                      <Typography variant="h3" sx={{ textAlign: "center" }}>
                        {item.name}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Stack
                        sx={{
                          width: "100%",
                          gap: 2,
                          px: 2,
                          textAlign: "center",
                        }}
                      >
                        <Stack>
                          <Typography sx={{ color: "grey.800" }}>
                            {typeof item.amount === "number"
                              ? `$${item.amount}/month`
                              : item.amount}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "grey.600",
                              textTransform: "uppercase",
                              opacity: index === tiers.length - 1 ? 0 : 1,
                            }}
                          >
                            per litigator
                          </Typography>
                        </Stack>
                        <Button
                          component="label"
                          fullWidth
                          variant="contained"
                          color={tier === item.name ? "primary" : "secondary"}
                          htmlFor={item.name}
                        >
                          {tier === item.name ? "Selected" : "Choose"}
                        </Button>
                      </Stack>
                      <List>
                        {item.options.map((option, indx) => (
                          <ListItem key={option} sx={{ gap: 1 }}>
                            <ListItemIcon sx={{ mr: 0, minWidth: "auto" }}>
                              <CheckIcon color="tertiary" fontSize="caption" />
                            </ListItemIcon>
                            <Typography
                              sx={{
                                fontWeight:
                                  index !== 0 && indx === 0 ? 900 : 400,
                              }}
                              variant="body2"
                            >
                              {option}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                      {item?.extra && (
                        <Box
                          sx={{ px: 2, textAlign: "right", color: "grey.600" }}
                        >
                          <Typography variant="caption">
                            {item.extra}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Stack>
                </ListItem>
              </Fragment>
            ))}
          </List>
          <Typography variant="caption">
            *Included with additional fee
          </Typography>
        </RadioGroup>
      )}
    />
  );
};
