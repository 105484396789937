import * as api from "../../../services/api";

import { Box, CircularProgress } from "@mui/material";
import React, { Suspense, useEffect } from "react";

import { CiceroEmpty } from "./CiceroEmpty";
import { CiceroItems } from "./CiceroItems";
import { Default } from "../../layouts";
import { Error } from "../../components/Error/Error";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

export const useCompletedAssignments = (options = {}) => {
  return useQuery({
    queryKey: ["completedAssignments"],
    queryFn: async () => await api.get("/cicero/get_completed_assignments"),
    suspense: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    ...options,
  });
};

const CiceroView = () => {
  const location = useLocation();
  const {
    data: completedAssignments,
    isLoading,
    error,
    refetch,
  } = useCompletedAssignments();

  useEffect(() => {
    if (location.pathname === "/cicero") {
      refetch();
    }
  }, [location.pathname, refetch]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  if (error) {
    return <Error message={error.message} />;
  }

  const assignments = completedAssignments || [];

  return (
    <Box>
      {assignments.length > 0 ? (
        <CiceroItems assignments={assignments} />
      ) : (
        <CiceroEmpty />
      )}
    </Box>
  );
};

const LoadingFallback = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <CircularProgress size="3rem" />
  </Box>
);

export const Cicero = () => (
  <Default>
    <Error>
      <Suspense fallback={<LoadingFallback />}>
        <CiceroView />
      </Suspense>
    </Error>
  </Default>
);

export default Cicero;
