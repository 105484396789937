import * as api from "../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCompletedAssignments } from "../../ui/views/Cicero/Cicero";

export const useUpdateCicero = () => {
  const queryClient = useQueryClient();
  const { refetch } = useCompletedAssignments();

  const mutation = useMutation({
    mutationFn: (payload) =>
      api.patch(`/cicero`, { body: JSON.stringify(payload) }),
    onSuccess: () => {
      refetch();
    },
  });

  return mutation;
};

export const useDeleteCicero = () => {
  const queryClient = useQueryClient();
  const { refetch } = useCompletedAssignments();

  const mutation = useMutation({
    mutationFn: (activity_id) => api.deleted(`/cicero/${activity_id}`),
    onSuccess: () => {
      refetch();
    },
  });

  return mutation;
};
