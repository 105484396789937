import * as api from "../api";
import { useQuery } from "@tanstack/react-query";

const getConfig = async (config) => {
  const response = await api.get(`/config/${config}`);
  return response.config_value;
};

const useConfig = (paramName) => {
  return useQuery({
    queryKey: [paramName],
    queryFn: () => getConfig(paramName),
  });
};

export const useIsCiceroEnabled = () => {
  const { data } = useConfig("enable_cicero");
  return data === 1;
};

export const useLowSampleThreshold = () => {
  const { data } = useConfig("low_sample_threshold");
  return data;
};
