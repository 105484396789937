import * as api from "../../../services/api";

import {
  Box,
  Button,
  CircularProgress,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Suspense, useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { ArrowBack } from "@mui/icons-material";
import { Default } from "../../layouts";
import { DoctrinaCompareCard } from "./DoctrinaCompareCard";
import Grid from "@mui/material/Unstable_Grid2";
import { NavLink as RouterLink } from "react-router-dom";
import { compareDoctrinaState } from "../../../services/store";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { useSuspenseQueries } from "@tanstack/react-query";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const DoctrinaCompareView = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const compared = useAtomValue(compareDoctrinaState);

  const goBack = useCallback(
    (e) => {
      e.preventDefault();
      navigate(`/doctrina`);
    },
    [navigate]
  );

  useEffect(() => {
    if (compared.length === 0) {
      return navigate("/doctrina");
    }
  }, [compared, navigate]);

  const courts = useSuspenseQueries({
    queries: compared.map((id) => {
      return {
        queryKey: ["court", id],
        queryFn: async () => await api.get(`/courts/${id}`),
      };
    }),
  });

  return (
    <Stack minHeight="100vh" spacing={4}>
      <Box paddingTop={4} paddingX={4}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Link href="/doctrina" onClick={goBack} variant="h4">
                <Stack alignItems="center" gap={1} direction="row">
                  <ArrowBack fontSize="small" /> Back
                </Stack>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={1} paddingX={4}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="doctrina data tabs"
        >
          <Tab label="Jurisprudence" {...a11yProps(0)} />
          <Tab label="Sentiment" {...a11yProps(1)} />
          <Tab label="Tone" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box backgroundColor="grey.100" flexGrow={1} padding={4}>
        <Grid alignItems="stretch" minHeight="80vh" container spacing={2}>
          {courts?.map((court, index) => (
            <Grid xs={12} md={3} key={court.data.id}>
              <DoctrinaCompareCard
                active={index === 0}
                doctrina={court.data}
                primaryDoctrina={courts[0].data}
                activeTab={activeTab}
                key={court.data.id}
              />
            </Grid>
          ))}
          <Grid xs={12} md={3} alignSelf="stretch">
            <Stack
              border="1px dashed grey"
              borderColor="grey.300"
              height="100%"
              padding={3}
              justifyContent="center"
            >
              <Button to="/doctrina" component={RouterLink}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>Add Court</Typography>
                  <AddIcon
                    fontSize="sm"
                    sx={{ position: "relative", top: -1 }}
                  />
                </Stack>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export const DoctrinaCompare = () => (
  <Default>
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress size="3rem" />
        </Box>
      }
    >
      <DoctrinaCompareView />
    </Suspense>
  </Default>
);
