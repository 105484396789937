import {
  Button,
  ButtonGroup,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { ErrorBoundary } from "react-error-boundary";
import { QueryErrorResetBoundary } from "@tanstack/react-query";

export function Error(props) {
  const { children } = props;

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <Stack sx={{ gap: 2, p: 5 }}>
              <Typography variant="h2">
                There was an error loading this data.
              </Typography>
              <Typography>
                Please try reloading the data again by pressing the{" "}
                <strong>Reload Data</strong> button below. If you continue to
                have issues, please email us at{" "}
                <Link href="mailto:support@userhetoric.com">
                  support@userhetoric.com
                </Link>
                .
              </Typography>
              <Divider />
              <ButtonGroup>
                <Button
                  size="small"
                  variant="contained"
                  color="tertiary"
                  onClick={() => resetErrorBoundary()}
                >
                  Reload Data
                </Button>
              </ButtonGroup>
            </Stack>
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
