import { Box, Button, Paper, Stack, Typography, useTheme } from "@mui/material";
import { StatRadarGraph, normalizeComparedData } from "../StatRadarGraph";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Unstable_Grid2";
import { JudgesCardAsideAccordion } from "./JudgesCardAsideAccordion";
import { StatPieGraphText } from "../StatPieGraphText";
import { useState } from "react";

const JudgesCardAsideView = ({
  radarKeys = ["judge", "brief"],
  statColor = false,
  radarColors = false,
  courtJurisprudence,
  active = false,
  filled = false,
  showPolarity = false,
  compact = false,
  court,
  expandable = false,
  display_name,
  jurisprudence,
  donutData,
  political_affiliation,
  date_of_birth,
  race,
  gender,
  aba_ratings,
  simple = false,
  expandedDefault = false,
  aside = false,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(expandedDefault);

  return (
    <Box>
      <Stack
        backgroundColor="white"
        color="text.main"
        padding={3}
        spacing={3}
      >
        <Stack spacing={1}>
          <Typography variant="h3" as="h1">
            {display_name}
          </Typography>
          {court?.type && court?.name && (
            <Typography
              variant="h5"
              as="h3"
              color={simple && active ? "white" : "text.light"}
            >
              Judge at {court.type}, {court.name}
            </Typography>
          )}
        </Stack>
        {!aside && (
          <Stack spacing={1}>
            {date_of_birth?.year && (
              <Typography variant="body2">
                Born: <strong>{date_of_birth.year}</strong>
              </Typography>
            )}
            {race && (
              <Typography variant="body2" textTransform="capitalize">
                Race: <strong>{race}</strong>
              </Typography>
            )}
            {gender && (
              <Typography variant="body2" textTransform="capitalize">
                Gender: <strong>{gender}</strong>
              </Typography>
            )}
            {aba_ratings?.year && (
              <Typography variant="body2" textTransform="capitalize">
                ABA Ratings:{" "}
                <strong>
                  {aba_ratings.rating} ({aba_ratings.year})
                </strong>
              </Typography>
            )}
            {political_affiliation && (
              <Typography variant="body2" textTransform="capitalize">
                Political: <strong>{political_affiliation}</strong>
              </Typography>
            )}
          </Stack>
        )}
        {aside && (
          <StatRadarGraph
            data={normalizeComparedData(courtJurisprudence, jurisprudence)}
            keys={radarKeys}
            indexBy="jurisprudence"
            colors={
              radarColors
                ? radarColors
                : [theme.palette.secondary.main, theme.palette.tertiary.main]
            }
          />
        )}
        {!aside && (
          <Grid container>
            {donutData &&
              Object.keys(donutData)?.length > 0 &&
              Object.keys(donutData)?.map((key) => (
                <Grid xs={6} key={key}>
                  <StatPieGraphText
                    color={statColor}
                    filled={filled}
                    showPolarity={showPolarity}
                    label={key}
                    active={active}
                    value={donutData[key]}
                    comparing={active}
                  />
                </Grid>
              ))}
          </Grid>
        )}
        {!compact && expanded && (
          <Stack>
            <JudgesCardAsideAccordion heading="Personal Info">
              <Stack spacing={1}>
                <Typography variant="body2">
                  Born: <strong>{date_of_birth.year}</strong>
                </Typography>
                <Typography variant="body2" textTransform="capitalize">
                  Race: <strong>{race}</strong>
                </Typography>
                <Typography variant="body2" textTransform="capitalize">
                  Gender: <strong>{gender}</strong>
                </Typography>
                <Typography variant="body2" textTransform="capitalize">
                  ABA Ratings:{" "}
                  <strong>
                    {aba_ratings.rating} ({aba_ratings.year})
                  </strong>
                </Typography>
              </Stack>
            </JudgesCardAsideAccordion>
            {political_affiliation && (
              <JudgesCardAsideAccordion heading="Political Information">
                <Typography textTransform="capitalize" variant="body2">
                  {political_affiliation} (via appointer)
                </Typography>
              </JudgesCardAsideAccordion>
            )}
          </Stack>
        )}
      </Stack>
      {expandable && (
        <Button
          background="white"
          bottom={0}
          sx={{
            backgroundColor: "#FEC722",
            left: "50%",
            transform: "translateX(-50%) translateY(50%)",
            padding: 0,
            display: "flex",
            minWidth: "auto",
            height: 42,
            width: 42,
          }}
          position="absolute"
          onClick={() => setExpanded(!expanded)}
          variant="secondary"
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      )}
    </Box>
  );
};

export const JudgesCardAside = ({ simple = false, ...props }) =>
  simple ? (
    <JudgesCardAsideView simple {...props} />
  ) : (
    <Paper>
      <Box position="relative">
        <JudgesCardAsideView filled aside={true} {...props} />
      </Box>
    </Paper>
  );
