import { Box, Stack, Typography } from "@mui/material";

import { Default } from "../../layouts/Default/Default";
import { useRouteError } from "react-router-dom";

const ErrorView = ({ error }) => (
  <Default>
    <Stack role="alert" spacing={4}>
      <Box paddingTop={4} paddingX={4}>
        <Typography>There was an error</Typography>
        <Typography color="red" as="pre">
          {error.message}
        </Typography>
      </Box>
    </Stack>
  </Default>
);

export const Error = ({ children }) => {
  const error = useRouteError();

  return <ErrorView error={error} />;
};
