import {
  Gavel as GavelIcon,
  Home as HomeIcon,
  Mic as MicIcon,
  Search as SearchIcon,
  Work as WorkIcon,
} from "@mui/icons-material";

import { Anchor } from "../../components";
import { Stack } from "@mui/material";
import { useIsCiceroEnabled } from "../../../services/hooks";

export const DefaultNavigation = () => {
  const isCiceroEnabled = useIsCiceroEnabled();

  return (
    <Stack flex={1} spacing={1}>
      <Anchor to="/" end>
        <HomeIcon />
        Home
      </Anchor>
      <Anchor to="/doctrina">
        <SearchIcon />
        Doctrina
      </Anchor>
      <Anchor to="/debrief">
        <WorkIcon />
        DeBrief
      </Anchor>
      <Anchor to="/judges">
        <GavelIcon />
        Judges
      </Anchor>
      {isCiceroEnabled && (
        <Anchor to="/cicero">
          <MicIcon />
          Cicero
        </Anchor>
      )}
    </Stack>
  );
};
