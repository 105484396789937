export const replaceItemAtIndex = (arr, index, newValue) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const removeItemAtIndex = (arr, index) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const makePrimaryCompare = (items, id) => {
  const index = items.findIndex((item) => item === id);
  const remaining = removeItemAtIndex(items, index);

  return [id, ...remaining];
};
