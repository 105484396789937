import { Link, Stack } from "@mui/material";

import { NavLink as RouterLink } from "react-router-dom";

export const Anchor = ({ to, children, end }) => {
  return (
    <Link
      sx={{
        "&:hover, &.active": {
          backgroundColor: "primary.light",
          color: "primary.main",
        },
      }}
      color="grey.600"
      borderRadius={1}
      paddingX={2}
      paddingY={1}
      fontWeight="800"
      underline="none"
      component={RouterLink}
      to={to}
      variant="contained"
      end={end}
    >
      <Stack
        direction="row"
        gap={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        {children}
      </Stack>
    </Link>
  );
};
