import { Box, CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import { Suspense, useMemo } from "react";
import { sidebarOpenAtom, summaryTabs } from "../../../services/store";
import { useAtom, useSetAtom } from "jotai";

import { ErrorBoundary } from "react-error-boundary";
import { HTMLParser } from "../../components/HTMLParser";
import { JudgeCourtAnalysisSummary } from "../JudgeCourtSummary";
import { JudgeCourtQuotes } from "../JudgeCourtQuotes";

export function JudgeCourtSummaryTabs({
  isJudge,
  judgeCourtId,
  briefSummary,
  isAggregate,
  isCourtX,
  isJudgeX,
}) {
  const setAsideOpen = useSetAtom(sidebarOpenAtom);
  const type = useMemo(() => (isJudge ? "judge" : "court"), [isJudge]);
  const [tab, setTab] = useAtom(summaryTabs(`${type}-${judgeCourtId}`));

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleClicks = {
    ReWrite: (event) => {
      event.preventDefault();
      setAsideOpen(true);
    },
    "Quotes Table": (event) => {
      event.preventDefault();
      setTab(briefSummary ? 2 : 1);
    },
  };

  const tabLabels = useMemo(() => {
    const labels = [];
    if (briefSummary) labels.push("Brief Summary");
    labels.push(isJudge ? "Judge Summary" : "Court Summary");
    if (!isCourtX && !isJudgeX) {
      labels.push("Quotes");
    }
    return labels;
  }, [briefSummary, isJudge, isCourtX, isJudgeX]);

  return (
    <Stack sx={{ gap: 4 }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        sx={{
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "grey.200",
        }}
      >
        {tabLabels.map((label, index) => (
          <Tab key={index} label={label} />
        ))}
      </Tabs>
      {briefSummary && (
        <Box hidden={tab !== 0}>
          <HTMLParser content={briefSummary} handleClicks={handleClicks} />
        </Box>
      )}
      <Box hidden={tab !== (briefSummary ? 1 : 0)}>
        <ErrorBoundary fallbackRender={() => "There was an error."}>
          <Suspense
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
              >
                <CircularProgress size="2rem" />
              </Box>
            }
          >
            <JudgeCourtAnalysisSummary id={judgeCourtId} type={type} />
          </Suspense>
        </ErrorBoundary>
      </Box>

      <Box hidden={tab !== (briefSummary ? 2 : 1)}>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress size="2rem" />
            </Box>
          }
        >
          <JudgeCourtQuotes
            id={judgeCourtId}
            type={`${type}s`}
            isAggregate={isAggregate}
          />
        </Suspense>
      </Box>
    </Stack>
  );
}

export default JudgeCourtSummaryTabs;
