import { Button, FormHelperText, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import { TextField } from "../../components";
import { useAuth } from "../../../services/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  email: "",
};

export const RequestResetForm = () => {
  const [genericError, setGenericError] = useState("");
  const navigate = useNavigate();
  const { handleForgotPassword } = useAuth();

  const {
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    setGenericError("");
    try {
      await handleForgotPassword(data.email);
      navigate("/reset/password");
    } catch (error) {
      setGenericError(error.message);
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField
          control={control}
          rules={{ required: "A valid email is required." }}
          name="email"
          helperText={errors?.email?.message}
          label="Email"
        />
        <Button
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          size="large"
        >
          Initiate Reset Request
        </Button>
        {genericError && <FormHelperText error>{genericError}</FormHelperText>}
      </Stack>
    </form>
  );
};
