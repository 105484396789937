import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Default } from "../../layouts";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { ProductCard } from "../../components";
import { useAuth } from "../../../services/auth";
import { useIsCiceroEnabled } from "../../../services/hooks";

export const Home = () => {
  const theme = useTheme();
  const { handleLogout } = useAuth();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const isCiceroEnabled = useIsCiceroEnabled();

  return (
    <Default>
      <Box padding={4}>
        <Grid alignItems="stretch" container rowSpacing={3} columnSpacing={5}>
          <Grid xs={12}>
            <Typography variant="h1">Your Products</Typography>
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="Doctrina"
              content="Doctrina helps you get a holistic view of courts, their composition, and their language preferences."
              action={
                <Button
                  component={Link}
                  to="/doctrina"
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="DeBrief"
              content="DeBrief is a tool that helps you make legal arguments that are custom tailored for the judges handling your cases."
              action={
                <Button
                  component={Link}
                  to="/debrief"
                  color="secondary"
                  size="small"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="Judges"
              content="Judges allows you to search for individual judges based on their philosophies of the law, political affiliations, and more."
              action={
                <Button
                  component={Link}
                  to="/judges"
                  color="secondary"
                  size="small"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          {isCiceroEnabled && (
            <Grid xs={12} lg={6}>
              <ProductCard
                heading="Cicero"
                content="Cicero is a tool that helps you practice and refine your oral presentations."
                action={
                  <Button
                    component={Link}
                    to="/cicero"
                    size="small"
                    color="secondary"
                    variant="contained"
                  >
                    View
                  </Button>
                }
              />
            </Grid>
          )}
          {!md && (
            <Grid xs={12}>
              <Stack justifyContent="flex-end">
                <Button onClick={handleLogout}>Logout</Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    </Default>
  );
};
