import * as api from "../../../services/api";

import { Button, CircularProgress } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Checkbox } from "../Checkbox";
import { useForm } from "react-hook-form";
import { useSetAtom } from "jotai";
import { userAgreementModal } from "../../../services/store";

const defaultValues = { user_agreement_signature_needed: false };

export const UserAgreementForm = () => {
  const queryClient = useQueryClient();
  const setOpen = useSetAtom(userAgreementModal);

  const updateUser = useMutation({
    mutationFn: async () => {
      await api.post("/user", {
        body: JSON.stringify({
          user_agreement_signature_needed: false,
        }),
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ["user"] });
      await queryClient.refetchQueries({ queryKey: ["user"] });
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async () => {
    try {
      await updateUser.mutateAsync();
      setOpen(false);
    } catch (error) {
      console.log([error]);
    }
  };

  const watchAgreement = watch("user_agreement_signature_needed", false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Checkbox
        rules={{ required: true }}
        name="user_agreement_signature_needed"
        label="Accept user agreement"
        control={control}
      />
      <Button
        type="submit"
        disabled={!watchAgreement || isSubmitting}
        variant="contained"
      >
        {isSubmitting ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          "Accept Terms"
        )}
      </Button>
    </form>
  );
};
