import { Box, Stack } from "@mui/material";

import { ResponsiveRadar } from "@nivo/radar";

export const normalizeData = (data) =>
  Object.keys(data).map((key) => ({
    jurisprudence: key,
    judge: data[key],
  }));

export const normalizeComparedData = (data, compare) =>
  Object.keys(data).map((key) => ({
    jurisprudence: key,
    judge: compare[key],
    court: compare[key],
    brief: data[key],
  }));

export const normalizeKeys = (data) => Object.keys(data);

export const StatRadarGraph = ({
  data,
  keys,
  indexBy,
  colors = ["#000", "#333", "#555"],
}) => {
  return (
    <Stack>
      <Box width={300} height={300} textTransform="capitalize">
        <ResponsiveRadar
          animate={false}
          data={data}
          keys={keys}
          indexBy={indexBy}
          margin={{ top: 20, right: 70, bottom: 20, left: 70 }}
          borderColor={{ from: "color" }}
          gridLabelOffset={20}
          dotSize={5}
          dotColor={{ from: "color", modifiers: [] }}
          dotBorderWidth={1}
          fillOpacity={0.5}
          colors={colors}
          blendMode="normal"
          motionConfig="wobbly"
          gridShape="linear"
        />
      </Box>
    </Stack>
  );
};
