import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

export const DebriefUploadStep = ({
  step,
  icon: Icon,
  heading,
  current,
  completed,
}) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  let color = "grey.400";

  if (current) {
    color = "primary.main";
  }

  if (completed) {
    color = "green";
  }

  return (
    <Stack alignItems="center" spacing={[1, 3]}>
      {Icon && (
        <Icon
          color={color}
          sx={{ color: color, width: md ? 100 : 50, height: md ? 100 : 50 }}
        />
      )}
      <Stack
        alignItems="center"
        justifyContent="center"
        backgroundColor={color}
        borderRadius="100%"
        color="white"
        height={[25, 55]}
        width={[25, 55]}
      >
        <Typography variant="h4" fontWeight="800">
          {step}
        </Typography>
      </Stack>
      <Stack spacing={1} alignItems="center">
        <Typography
          variant="h4"
          fontWeight="800"
          color={current ? color : "grey.800"}
        >
          {heading}
        </Typography>
        {completed && <Typography>complete</Typography>}
      </Stack>
    </Stack>
  );
};
