import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";

import { Controller } from "react-hook-form";

export const Checkbox = ({ label, name, control, rules }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <MuiCheckbox
              {...field}
              inputProps={{
                "data-cy": `checkbox-${name}`,
              }}
              checked={field.value}
              onChange={(_, value) => {
                field.onChange(value);
              }}
            />
          )}
        />
      }
      label={label}
    />
  );
};
