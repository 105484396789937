import { Button, FormGroup, FormHelperText, Stack } from "@mui/material";
import { Checkbox, TextField } from "../../components";
import { useEffect, useState } from "react";

import { hotjar } from "react-hotjar";
import { useAuth } from "../../../services/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  email: "",
  password: "",
};

export const LoginForm = () => {
  const navigate = useNavigate();
  const [genericError, setGenericError] = useState("");

  const {
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  const { handleLogin } = useAuth();

  const onSubmit = async ({ email, password }) => {
    try {
      setGenericError("");

      const { ok } = await handleLogin(email, password);

      if (ok) {
        if (hotjar.initialized()) {
          hotjar.identify(email, {});
        }
        navigate("/");
      }
    } catch (error) {
      setGenericError(error.message);
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form data-cy="form-login" onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField
          label="Email"
          helperText={errors?.email?.message}
          name="email"
          data-cy="text-field-email"
          control={control}
          rules={{ required: "A valid email is required." }}
        />
        <TextField
          label="Password"
          type="password"
          name="password"
          data-cy="text-field-password"
          helperText={errors?.password?.message}
          control={control}
          rules={{ required: "Your password is required." }}
        />
        <Button
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          size="large"
          data-cy="form-login-submit"
        >
          Submit
        </Button>
        <FormGroup>
          <Checkbox name="remember" control={control} label="Remember me" />
        </FormGroup>
        {genericError && <FormHelperText error>{genericError}</FormHelperText>}
      </Stack>
    </form>
  );
};
