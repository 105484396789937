import * as api from "../../../services/api";

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { atom, useAtom, useSetAtom } from "jotai";

import { DeBriefEmpty } from "./DeBriefEmpty";
import { DeBriefItems } from "./DeBriefItems";
import { Default } from "../../layouts";
import { Error } from "../../components/Error/Error";
import Grid from "@mui/material/Unstable_Grid2";
import { SidebarContent } from "../../components";
import { sidebarOpenAtom } from "../../../services/store";
import { useInfiniteQuery } from "@tanstack/react-query";

const selectedAtom = atom(-1);

const EmptyAside = () => (
  <SidebarContent heading="Support">
    <Stack spacing={3}>
      <Stack>
        <Typography>
          DeBrief is a tool that helps you make legal arguments that are custom
          tailored for the judges handling your cases. To do this, we analyze
          that judge and their jurisprudence - or philosophy of the law - and
          compare it to what you’ve written in your brief. Listed below are the
          primary jurisprudence types we analyze.
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h4">Tradition</Typography>
        <Typography>
          Arguments based on tradition frequently refer to history and the
          development of our legal traditions.
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h4">Textualism</Typography>
        <Typography>
          Textualism refers to the interpretation of the text at issue without
          reference to outside sources or documents, such as legislative
          history.
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h4">Precedent</Typography>
        <Typography>
          Judges who show a strong preference for arguments based on precedent
          closely rely on established law drawn from prior decisions by their
          court or higher courts.
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h4">Policy</Typography>
        <Typography>
          Policy-based arguments are often based on social and moral values and
          generally aim to produce social or political outcomes.
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h4">Purposivist</Typography>
        <Typography>
          Purposivist arguments are frequently concerned with the intentions of
          the drafters of the statute at issue in a case. Commonly, arguments of
          this type refer to the intent of Congress and state legislatures, for
          example.
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h4">Originalist</Typography>
        <Typography>
          Originalist arguments are often based on the original public meaning
          of key portions of Constitutions when they were written.
        </Typography>
      </Stack>
    </Stack>
  </SidebarContent>
);

const DeBriefView = () => {
  const setSelected = useSetAtom(selectedAtom);

  const handleAsideSideEffect = useCallback(() => {
    setSelected(-1);
  }, [setSelected]);

  const aside = useMemo(() => {
    return <EmptyAside />;
  }, []);

  return (
    <Default
      handleAsideSideEffect={handleAsideSideEffect}
      aside={
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          }
        >
          {aside}
        </Suspense>
      }
    >
      <Error>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          }
        >
          <Fetch />
        </Suspense>
      </Error>
    </Default>
  );
};

function Fetch() {
  const setAsideOpen = useSetAtom(sidebarOpenAtom);
  const [selected, setSelected] = useAtom(selectedAtom);
  const [page, setPage] = useState(1);

  const handleSelect = useCallback(
    (value) => {
      setAsideOpen(false);
      setSelected(value);
    },
    [setAsideOpen, setSelected]
  );

  const {
    isLoading,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: ["briefs"],
    queryFn: async ({ pageParam = 1 }) =>
      await api.get(`/v2/briefs?page=${pageParam}`),
    getNextPageParam: (lastPage) => {
      return lastPage.num_pages === page ? undefined : page + 1;
    },
    throwOnError: true,
  });

  const handleFetchMore = useCallback(() => {
    const next = page + 1;
    setPage(next);
    fetchNextPage();
  }, [fetchNextPage, page]);

  useEffect(() => {
    if (isRefetching) {
      setPage(1);
    }
  }, [isRefetching]);

  const items = useMemo(() => {
    return data?.pages?.map((data) => data.briefs).flat() || [];
  }, [data]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  return (
    <>
      {items.length === 0 && (
        <Box padding={4}>
          <Grid container spacing={2}>
            <DeBriefEmpty />
          </Grid>
        </Box>
      )}
      {items && items?.length !== 0 && (
        <DeBriefItems
          selected={selected}
          setSelected={handleSelect}
          items={items}
        />
      )}

      {(isFetchingNextPage || hasNextPage) && items && items?.length !== 0 && (
        <Stack sx={{ py: 4 }}>
          <Button onClick={handleFetchMore} variant="contained">
            {isFetchingNextPage ? (
              <CircularProgress color="inherit" size="3rem" />
            ) : (
              "Load More"
            )}
          </Button>
        </Stack>
      )}
    </>
  );
}

export const DeBrief = () => <DeBriefView />;
