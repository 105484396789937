import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { briefOptions } from "../../../services/queries";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

function createVersionLabel(version) {
  if (
    !version?.case_name &&
    !version?.client_number &&
    !version.matter_number
  ) {
    return `Version ${version.version}`;
  }

  return `V${version.version}${
    version.case_name ? ` – ${version.case_name}` : ""
  }${version.client_number ? ` – ${version.client_number}` : ""}${
    version.matter_number ? ` – ${version.matter_number}` : ""
  }`;
}

export const DeBriefCardFilter = ({
  handleSetVersion,
  briefId,
  selectedVersionId,
}) => {
  const { data, isLoading, error } = useQuery(briefOptions(briefId));

  const options = useMemo(() => {
    return data?.versions?.map((version) => ({
      id: version.brief_version_id,
      label: createVersionLabel(version),
    }));
  }, [data]);

  const defaultValue = useMemo(() => {
    return options?.find((option) => option.id === +selectedVersionId) || null;
  }, [options, selectedVersionId]);

  const handleVersionChange = (event, value) => {
    handleSetVersion(value.id);
  };

  if (error) {
    return <div>Error loading versions</div>;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Autocomplete
      loading={isLoading}
      size="small"
      sx={{ flex: 2 }}
      onChange={handleVersionChange}
      autoHighlight
      disableClearable
      value={defaultValue}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Versions"
          label="Version"
          inputProps={{ ...params.inputProps, readOnly: true }}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: "1rem" },
          }}
        />
      )}
    />
  );
};
