import * as api from "../../../services/api";

import { Box, Typography } from "@mui/material";

import { DoctrinaCard } from "../../components";
import { JudgeCourtSummaryTabs } from "../../components/JudgeCourtSummaryTabs";
import { courtSubjectsAtom } from "../../../services/store";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";

export const CourtContainer = ({ id }) => {
  const subjects = useAtomValue(courtSubjectsAtom(id));

  const params = decodeURIComponent(
    new URLSearchParams({
      subject: subjects.map((subject) => subject.id).join(","),
    }).toString()
  );

  const { data: court, error } = useSuspenseQuery({
    queryKey: ["courts", id, params],
    queryFn: async () => {
      return await api.get(
        `/courts/${id}${subjects?.length ? `?${params}` : ""}`
      );
    },
    refetchOnWindowFocus: false,
  });

  const isAggregate = useMemo(() => court.is_aggregate !== undefined, [court]);
  const isCourtX = useMemo(() => court.is_court_x === 1, [court]);

  if (error) {
    return <Typography>Error fetching data: {error.message}</Typography>;
  }

  if (!court) {
    return (
      <Box paddingTop={4} paddingX={4}>
        <Typography>No data found</Typography>
      </Box>
    );
  }

  return (
    <>
      <DoctrinaCard {...court} isDetailPage={true} />
      <JudgeCourtSummaryTabs
        judgeCourtId={id}
        isAggregate={isAggregate}
        isCourtX={isCourtX}
      />
    </>
  );
};
