import React from "react";
import { Box, Stack } from "@mui/material";
import { DebriefUploadStep as CiceroStep } from "../../components";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import MicIcon from "@mui/icons-material/Mic";

const Divider = () => (
  <Box
    flexGrow={1}
    height={3}
    backgroundColor="grey.200"
    marginX={-4}
    position="relative"
    top={150}
    maxWidth={300}
    zIndex={1}
  />
);

export const CiceroCreateSteps = ({ current = 1 }) => {
  return (
    <Stack
      alignItems={["center", "flex-start"]}
      justifyContent="center"
      flexDirection={["column", "row"]}
      spacing={[2, 0]}
    >
      <Box position="relative" zIndex={2}>
        <CiceroStep
          step={1}
          icon={FormatListBulletedIcon}
          heading="Case Details"
          current={current === 1}
          completed={current > 1}
        />
      </Box>
      <Divider />
      <Box position="relative" zIndex={2}>
        <CiceroStep
          step={2}
          icon={RecordVoiceOverIcon}
          heading="Select Recording Type"
          current={current === 2}
          completed={current > 2}
        />
      </Box>
      <Divider />
      <Box position="relative" zIndex={2}>
        <CiceroStep
          step={3}
          icon={MicIcon}
          heading="Begin Recording"
          current={current === 3}
          completed={current > 3}
        />
      </Box>
    </Stack>
  );
};
export default CiceroCreateSteps;
