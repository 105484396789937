import * as api from "../../../services/api";

import { ButtonGroup, IconButton } from "@mui/material";

import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useCallback } from "react";
import { useState } from "react";

export const QuoteVote = ({ id, vote = 0, type }) => {
  const [internalVote, setInternalVote] = useState(vote);

  const handleVote = useCallback(
    async (direction) => {
      const response = await api.post("/quote/quote_vote", {
        body: JSON.stringify({ quote_id: id, direction, type }),
      });

      return await response.json();
    },
    [id, type]
  );

  const handleUpVote = useCallback(async () => {
    setInternalVote(1);
    await handleVote("up");
  }, [handleVote]);

  const handleDownVote = useCallback(async () => {
    setInternalVote(-1);
    await handleVote("down");
  }, [handleVote]);

  const handleNeutralVote = useCallback(async () => {
    setInternalVote(0);
    await handleVote("neutral");
  }, [handleVote]);

  return (
    <ButtonGroup orientation="vertical">
      <IconButton
        onClick={internalVote === 1 ? handleNeutralVote : handleUpVote}
        color={internalVote === 1 ? "primary" : ""}
      >
        <ThumbUpIcon sx={{ width: 18, height: 18 }} />
      </IconButton>
      <IconButton
        onClick={internalVote === -1 ? handleNeutralVote : handleDownVote}
        color={internalVote === -1 ? "primary" : ""}
      >
        <ThumbDownIcon sx={{ width: 18, height: 18 }} />
      </IconButton>
    </ButtonGroup>
  );
};
