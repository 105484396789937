import * as api from "../api";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useDropzone } from "react-dropzone";

export const useDeleteBrief = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (id) => await api.deleted(`/briefs/${id}`),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: ["briefs"] });
      const previousBriefs = queryClient.getQueryData(["briefs"]);

      queryClient.setQueryData(["briefs"], (oldData) => {
        const newData = {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            briefs: page.briefs.filter((brief) => brief.id !== id),
            total_brief_count: page.total_brief_count - 1,
          })),
        };

        return newData;
      });

      return { previousBriefs };
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["briefs"] });
      await queryClient.refetchQueries({
        queryKey: ["briefs"],
        exact: true,
      });
    },
  });

  return mutation;
};

export const useUploadBriefFile = ({ onDrop }) => {
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  return { acceptedFiles, getRootProps, getInputProps, open };
};

export const useUpdateBrief = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload) =>
      api.patch(`/briefs`, { body: JSON.stringify(payload) }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["briefs"] });
    },
  });

  return mutation;
};
