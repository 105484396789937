export const jurisprudence = {
  textualist:
    "Textualist arguments are focused on understanding the text of the laws interpreted without reference to materials such as legislative history, and often use terms like “plain text” and “ordinary meaning,” to name a few.",
  traditionalist:
    "Arguments based on tradition frequently refer to history, the development of our legal traditions, and influences on our legal system, such as British common law.",
  precedent:
    "Arguments based on precedent are grounded in prior opinions and rely on reasoning that has been effective in influencing courts in the past and may also seek to maintain the legal status quo.",
  policy:
    "Policy arguments are typically based on social and moral values, fairness, efforts to produce certain outcomes in individual cases, and the consequences of the court’s decision in the case at hand.",
  purposivist:
    "Purposivist arguments are frequently concerned with the intentions of the drafters of the statute at issue in a case. Commonly, arguments of this type refer to the intent of Congress and state legislatures, for example.",
  originalist:
    "Originalist arguments are often based on the original public meaning of key portions of Constitutions when they were written.",
};

export const sentiment = {
  positive:
    "Positive sentiment refers to instances where the judge expresses approval, support, or agreement with a particular legal argument, interpretation, or outcome. Positive sentiment can manifest through language that endorses a specific legal reasoning, applauds well-presented evidence, or acknowledges a persuasive line of argument.",
  negative:
    "Negative sentiment indicates the judge's disapproval, disagreement, or criticism of a legal point, interpretation, or conclusion. Negative sentiment may be conveyed through language that challenges the validity of an argument, highlights shortcomings in presented evidence, or raises concerns about the logic behind a legal stance.",
  formal:
    "Formal language within a legal opinion involves assessing the judge's adherence to established legal terminology, the norms of the profession, and structured expression.",
  informal:
    "Informal language use might stand out as a departure from typical legal discourse. Informal language could indicate a more relaxed or accessible tone, potentially offering effective explanations or summaries in simpler terms.",
  empathy:
    "Empathy pertains to instances where the judge demonstrates understanding and sensitivity towards the parties involved in the legal matter and/or the lasting effects on non-parties. This could involve acknowledging the potential impact of the decision on individuals' lives, showing compassion while discussing complex issues, and recognizing the human element amidst legal complexities.",
  detachment:
    "Detachment refers to the judge's ability to maintain an objective and impartial tone throughout the text. This is achieved by expressing legal reasoning, interpretations, and conclusions without the overt influence of personal emotions or biases.",
};

export const tone = {
  agreeableness:
    "Agreeableness refers to the judge's use of cooperative language when addressing legal arguments, parties, or conflicting viewpoints. An agreeable tone may indicate an effort to find common ground, acknowledge valid points, and foster a sense of collaboration.",
  antagonistic:
    "An antagonistic approach employs confrontational or hostile language. Such language may be directed towards a legal argument, a party, or a line of reasoning, and it can reflect a strong disagreement or the expression of negative sentiment towards a particular aspect of the case.",
  openness:
    "Openness pertains to the judge's willingness to consider diverse legal perspectives, novel interpretations, and innovative arguments. An open-minded approach can be discerned through language that acknowledges alternative viewpoints, engages with counterarguments, and demonstrates a receptiveness to evolving legal principles.",
  obstinance:
    "Obstinance refers to an inflexible disposition demonstrated by the judge. This might manifest as language that consistently adheres to a particular interpretation or legal doctrine without demonstrating a willingness to consider opposing viewpoints.",
  eccentricity:
    "Eccentricity refers to the use of dramatic, sarcastic, and/or whimsical language utilized by the judge. Such language could include folksy sayings, common expressions, and humorous references. ",
  stoicism:
    "Stoicism refers to calm, rational, and composed demeanor in language, particularly when discussing emotionally charged or sensitive legal issues.",
};

export const summary = {
  rhetoric_score:
    "The Rhetoric Score is a composite metric that synthesizes the results of Jurisprudence Analysis, Sentiment Analysis, and Tone Analysis. It provides a holistic evaluation of a judge's preferences, which is then used to assess the strengths and weaknesses of the user's arguments presented in their brief. The Rhetoric Score enables users to quickly dissect their brief, identifying opportunities to improve their odds of persuading the judge(s) by incorporating their preferred language and arguments.",
  tone: "Tone Analysis focuses on deciphering the communication style and attitude of a judge as conveyed through their language. This category examines linguistic cues, word choice, and rhetorical devices to characterize tone across six dimensions: Agreeableness, Antagonistic, Openness, Obstinance, Eccentricity, and Stoicism. By assessing tone, Rhetoric helps you persuade judges by framing your arguments in the tone that resonates best.",
  jurisprudence:
    "Jurisprudence Analysis refers to the systematic examination and evaluation of the legal doctrines that judges rely on to decide cases. This category delves into the judge's preferences for various interpretations and applications of the law, assessing their legal reasoning. By analyzing jurisprudence, Rhetoric provides insights into the intellectual foundation and legal philosophy that underpin each judge's rulings.",
  sentiment:
    "Sentiment Analysis involves the assessment of the emotional and subjective dispositions present in a judge's opinions. This category discerns the prevailing sentiments expressed in the text, which fall across three ranges: from positive to negative, formal to informal, and empathetic to detached. By identifying and quantifying emotions and attitudes conveyed in the text, sentiment analysis offers a nuanced understanding of how the judge's personal stances might influence the decision-making process.",
};
