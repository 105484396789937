import { Box, Stack, Typography } from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";

export const SidebarContent = ({ heading, children }) => (
  <Stack paddingX={3}>
    <Stack alignItems="center" direction="row" paddingY={3} spacing={1}>
      <HelpIcon sx={{ fontSize: 32, color: "secondary.main" }} />
      <Typography variant="h3" as="h3">
        {heading}
      </Typography>
    </Stack>
    <Box>{children}</Box>
  </Stack>
);
