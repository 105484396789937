import * as api from "../../../services/api";

import {
  Button,
  CircularProgress,
  Unstable_Grid2 as Grid,
  Stack,
} from "@mui/material";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { Checkbox } from "../../components/Checkbox";
import { TextField } from "../../components/TextField";
import { createUserRegistration } from "../../../services/airtable";
import { useAuth } from "../../../services/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { userOptions } from "../../../services/queries";

const defaultValues = {
  user_agreement_signature_needed: false,
  first_name: "",
  last_name: "",
  role: "",
  company: "",
};

export const UserRegistrationForm = ({ children }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { handleLogout } = useAuth();
  const { data: user } = useSuspenseQuery(userOptions());
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      const response = await api.post("/user", {
        body: JSON.stringify({
          ...data,
          user_agreement_signature_needed: false,
          user_registration_needed: false,
        }),
      });

      queryClient.invalidateQueries({ queryKey: ["user"] });

      createUserRegistration({
        fields: {
          "First name": data.first_name,
          "Last name": data.last_name,
          Role: data.role,
          Company: data.company,
          Email: user.email,
          Environment: process.env.REACT_APP_ENVIRONMENT,
        },
      });

      if (response?.success) {
        navigate(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <TextField
            trim={false}
            control={control}
            name="first_name"
            label="First name"
            helperText="Required*"
            rules={{ required: "First name is required" }}
            errors={!!errors?.first_name}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            trim={false}
            control={control}
            name="last_name"
            label="Last name"
            helperText="Required*"
            rules={{ required: "Last name is required" }}
            errors={!!errors?.last_name}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField trim={false} control={control} name="role" label="Role" />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            trim={false}
            control={control}
            name="company"
            label="Company"
          />
        </Grid>
        {user?.user_agreement_signature_needed && (
          <Grid xs={12}>
            {children}
            <Checkbox
              rules={{ required: true }}
              name="user_agreement_signature_needed"
              label="Accept user agreement"
              control={control}
            />
          </Grid>
        )}
        <Grid xs={12}>
          <Stack direction={["column", "row"]} justifyContent="space-between">
            <Button type="submit" disabled={isSubmitting} variant="contained">
              {isSubmitting ? (
                <CircularProgress color="inherit" size={30} />
              ) : (
                "Complete Registration"
              )}
            </Button>
            <Button variant="text" onClick={handleLogout}>
              Logout
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
