import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

export const summaryTabs = atomFamily((key) => atom(0));
export const uploadModalAtom = atomFamily((key) => atom(false));

export const courtSubjectsAtom = atomFamily((key) => atom([]));

export const userAgreementModal = atom(false);

export const compareJudgesState = atom([]);
export const canCompareJudgesState = atom(
  (get) => get(compareJudgesState).length > 1
);

export const compareDoctrinaState = atom([]);
export const canCompareDoctrinaState = atom(
  (get) => get(compareDoctrinaState).length > 1
);

export const drawerJudgeSearchState = atom(false);

export const authTemp = atom({
  email: "",
  password: "",
});

export const sidebarOpenAtom = atom(false);
export const paymentModal = atom(false);
export const drawerDoctrinaSearchState = atom(false);

export const uploadDeBriefState = atom({
  files: "",
  judge: "",
  judgesNames: [],
  court: "",
  courtName: "",
});

export const createDebriefState = atom({
  step: 1,
  formData: {},
});

export * from "./helpers";
