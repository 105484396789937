import { Button } from "@mui/material";
import { diff as deepDiff } from "deep-object-diff";
import { hotjar } from "react-hotjar";
import { judgesDefaultValues } from "../SearchJudges";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const ResetJudgesSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const values = {};
  searchParams.forEach((value, key) => (values[key] = value));

  const diff = deepDiff(judgesDefaultValues, values);

  const handleReset = useCallback(() => {
    setSearchParams([]);
    hotjar.event("search-judges-reset");
  }, [setSearchParams]);

  const filters = Object.keys(diff)
    .filter((key) => diff[key] !== "")
    .filter((key) => diff[key] !== "false")
    .filter((key) => diff[key] !== undefined).length;

  return (
    <Button onClick={handleReset} variant="outlined" type="button">
      Reset Filters {!!filters && `(${filters})`}
    </Button>
  );
};
