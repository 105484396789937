import { CircularProgress, Stack, Box } from "@mui/material";

import { CourtContainer } from "./CourtContainer";
import { CourtFilter } from "./CourtFilter";
import { Default } from "../../layouts";
import { Suspense } from "react";
import { memo } from "react";
import { useParams } from "react-router-dom";

const CourtView = memo(() => {
  const { id } = useParams();

  return (
    <Default>
      <Stack sx={{ p: [0, 4], gap: 2 }}>
        <CourtFilter id={id} />
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          }
        >
          <CourtContainer id={id} />
        </Suspense>
      </Stack>
    </Default>
  );
});

export const Court = () => <CourtView />;

export default Court;
