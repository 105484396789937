import { Box, Stack } from "@mui/material";

import { DebriefUploadStep as DebriefStep } from "../../components";
import GavelIcon from "@mui/icons-material/Gavel";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const Divider = () => (
  <Box
    flexGrow={1}
    height={3}
    backgroundColor="grey.200"
    marginX={-4}
    position="relative"
    top={150}
    maxWidth={300}
    zIndex={1}
  />
);

export const DeBriefCreateSteps = ({ current = 1 }) => {
  return (
    <Stack
      alignItems={["center", "flex-start"]}
      justifyContent="center"
      flexDirection={["column", "row"]}
      spacing={[2, 0]}
    >
      <Box position="relative" zIndex={2}>
        <DebriefStep
          step={1}
          icon={UploadFileIcon}
          heading="Select Document"
          current={current === 1}
          completed={current > 1}
        />
      </Box>
      <Divider />
      <Box position="relative" zIndex={2}>
        <DebriefStep
          step={2}
          icon={GavelIcon}
          heading="Choose a court or judge."
          current={current === 2}
          completed={current > 2}
        />
      </Box>
      <Divider />
      <Box position="relative" zIndex={2}>
        <DebriefStep
          step={3}
          icon={PublishedWithChangesIcon}
          heading="Process"
          current={current === 3}
          completed={current > 3}
        />
      </Box>
    </Stack>
  );
};
