import * as api from "../../../services/api";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Modal,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CheckCircleOutline,
  Close,
  Link as LinkIcon,
} from "@mui/icons-material";
import {
  QueryErrorResetBoundary,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { Suspense, useEffect, useMemo, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ErrorBoundary } from "react-error-boundary";
import Grid from "@mui/material/Unstable_Grid2";
import { QuoteVote } from "../../components";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useCopyToClipboard } from "../../../services/hooks/useCopyToClipboard";

const useColor = (value) => {
  const theme = useTheme();

  if (Math.abs(+value) < 10) return theme.palette.success.main;
  if (Math.abs(+value) < 20) return theme.palette.warning.main;
  return theme.palette.error.main;
};

export const DeBriefCaseContextCard = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Stack justifyContent="center" alignItems="center" height="100vh">
          <Box maxWidth={1100} width="80%">
            <DeBriefCaseContextCardBox
              setModalOpen={setModalOpen}
              modal
              {...props}
            />
          </Box>
        </Stack>
      </Modal>
      <DeBriefCaseContextCardBox setModalOpen={setModalOpen} {...props} />
    </>
  );
};

export const DeBriefCaseContextCardBoxLoader = () => {
  return (
    <Paper
      sx={{
        position: "relative",
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
        borderLeftColor: "neutral",
      }}
    >
      <Grid container spacing={0}>
        <Grid sm={12} md={4}>
          <Stack height="100%" backgroundColor="grey.200" padding={4} gap={3}>
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography variant="h4">
                <Skeleton width={100} />
              </Typography>
              <Typography variant="h4" fontWeight={300} color="grey.600">
                <Skeleton width={30} />
              </Typography>
            </Stack>
            <Typography variant="body2">
              <Skeleton />
            </Typography>
          </Stack>
        </Grid>
        <Grid sm={12} md={8}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            minHeight="200px"
          >
            <CircularProgress color="secondary" size={15} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const DeBriefCaseContextCardBox = ({
  setModalOpen,
  modal = false,
  measure,
  heading,
  delta,
  quotes,
  brief_id,
  brief_version_id,
}) => {
  const color = useColor(delta);

  const types = useMemo(() => {
    const t = quotes?.map((quote) => quote.type) ?? [];
    return [...new Set(t.filter((item) => item !== undefined))];
  }, [quotes]);

  return (
    <Paper
      sx={{
        position: "relative",
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
        borderLeftColor: color,
      }}
    >
      <Grid container spacing={0}>
        <Grid
          sm={12}
          md={4}
          sx={{
            display: modal ? ["none", "none", "block"] : null,
          }}
        >
          <Stack height="100%" backgroundColor="grey.200" padding={4} gap={3}>
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography variant="h4">{measure}</Typography>
              <Typography variant="h4" fontWeight={300} color="grey.600">
                {delta}
              </Typography>
            </Stack>
            <Typography variant="body2">{heading}</Typography>
          </Stack>
        </Grid>
        <Grid sm={12} md={8}>
          {modal && (
            <Stack
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
              paddingTop={[4]}
              paddingX={[4]}
            >
              <Typography>Quotes</Typography>
              <IconButton size="small" onClick={() => setModalOpen(false)}>
                <Close sx={{ width: 25, height: 25 }} />
              </IconButton>
            </Stack>
          )}
          <Box maxHeight="60vh" overflow="auto" height="100%">
            {Math.abs(delta) < 10 && (
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CheckCircleOutline
                  color="success"
                  sx={{ width: 120, height: 120 }}
                />
              </Stack>
            )}
            {Math.abs(delta) >= 10 && (
              <Stack gap={2} paddingY={[4]} paddingX={[4]}>
                {types.length !== 0 &&
                  types.slice(0, modal ? quotes?.length : 2).map((type) => {
                    const qts = quotes.filter((quote) => quote.type === type);
                    return (
                      <Stack
                        key={`quote-context-${type}-${measure}-${delta}`}
                        sx={{ gap: 1 }}
                      >
                        <Typography
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {type}
                        </Typography>
                        {qts
                          ?.slice(0, modal ? quotes?.length : 3)
                          ?.map(
                            (
                              {
                                quote,
                                explanation,
                                opinion_url,
                                quote_id,
                                quote_vote,
                                type,
                              },
                              index
                            ) => (
                              <DeBriefCaseContextItem
                                key={`quote-context-${index}-${measure}-${delta}`}
                                quote={quote ?? ""}
                                explanation={explanation ?? ""}
                                opinion_url={opinion_url ?? ""}
                                quote_id={quote_id}
                                quote_vote={quote_vote}
                                quote_type={type}
                              />
                            )
                          )}
                      </Stack>
                    );
                  })}
                {types.length === 0 &&
                  quotes
                    ?.slice(0, modal ? quotes?.length : 3)
                    ?.map(
                      (
                        {
                          quote,
                          explanation,
                          opinion_url,
                          quote_id,
                          quote_vote,
                          type,
                        },
                        index
                      ) => (
                        <DeBriefCaseContextItem
                          key={`quote-context-${index}-${measure}-${delta}`}
                          quote={quote ?? ""}
                          explanation={explanation ?? ""}
                          opinion_url={opinion_url ?? ""}
                          quote_id={quote_id}
                          quote_vote={quote_vote}
                          quote_type={type}
                        />
                      )
                    )}
                {types?.length === 0 && quotes?.length === 0 && (
                  <QueryErrorResetBoundary>
                    {({ reset }) => (
                      <ErrorBoundary
                        onReset={reset}
                        fallbackRender={({ resetErrorBoundary }) => (
                          <Stack sx={{ gap: 1 }}>
                            <Typography variant="body2">
                              There was an error loading these quotes.
                            </Typography>
                            <Button
                              size="small"
                              variant="contained"
                              color="tertiary"
                              onClick={() => resetErrorBoundary()}
                            >
                              Try again
                            </Button>
                          </Stack>
                        )}
                      >
                        <Suspense
                          fallback={
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              minHeight="100px"
                            >
                              <CircularProgress size="1.25rem" />
                            </Box>
                          }
                        >
                          <ExtraQuotes
                            brief_id={brief_id}
                            brief_version_id={brief_version_id}
                            delta={delta}
                            measure={measure}
                            cutoff={3 - quotes?.length || 0}
                            modal={modal}
                          />
                        </Suspense>
                      </ErrorBoundary>
                    )}
                  </QueryErrorResetBoundary>
                )}
              </Stack>
            )}
          </Box>
          {!modal &&
            !!quotes &&
            quotes?.length > 4 &&
            Math.abs(delta) >= 10 && (
              <Box
                backgroundColor="secondary.main"
                borderRadius={2}
                right={10}
                bottom={-10}
                position="absolute"
              >
                <IconButton size="small" onClick={() => setModalOpen(true)}>
                  <ZoomOutMapIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
              </Box>
            )}
        </Grid>
      </Grid>
    </Paper>
  );
};

const DeBriefCaseContextItem = ({
  quote,
  explanation,
  opinion_url,
  quote_id = -1,
  quote_vote = 0,
  quote_type,
}) => {
  const [, copy] = useCopyToClipboard();
  const [open, setOpen] = useState(false);

  const handleCopy = () => {
    copy(quote);
    setOpen(true);
  };

  return (
    <>
      <Stack
        alignItems={["flex-start", "flex-start", "center"]}
        flexDirection="row"
        gap={3}
        justifyContent="flex-start"
        textTransform={"none"}
        textAlign="left"
      >
        <Stack flexDirection={["column", "column", "row"]}>
          {quote_id !== -1 && (
            <>
              <QuoteVote id={quote_id} vote={quote_vote} type={quote_type} />
              <Divider sx={{ mb: 1, mt: 0.5 }} />
            </>
          )}
          <Stack>
            <IconButton onClick={handleCopy}>
              <ContentCopyIcon sx={{ width: 18, height: 18 }} />
            </IconButton>
            {opinion_url && (
              <IconButton
                color="primary"
                component={Link}
                href={opinion_url}
                target="_blank"
              >
                <LinkIcon sx={{ width: 18, height: 18 }} />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <Stack gap={0.5}>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: quote }}
          />
          <Typography variant="caption" color="grey.700">
            {explanation}
          </Typography>
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        message="Copied to your clipboard."
      />
    </>
  );
};

const ExtraQuotes = ({
  measure,
  brief_version_id,
  brief_id,
  delta,
  modal,
  cutoff,
}) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => [
      "extra_quotes",
      brief_version_id,
      brief_id,
      measure.toLowerCase(),
      delta.toString(),
    ],
    [brief_version_id, brief_id, measure, delta]
  );

  const { data: extra } = useSuspenseQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const params = {
        brief_id,
        brief_version_id,
        category: measure.toLowerCase(),
      };

      const response = await api.get(
        `/briefs/cached_quotes?${decodeURIComponent(
          new URLSearchParams(params).toString()
        )}`,
        { signal }
      );

      return response;
    },
    retry: 12,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    return () => queryClient.cancelQueries({ queryKey });
  }, [queryClient, queryKey]);

  const extraQuotes = extra?.[0]?.quotes || [];

  return (
    <>
      {extraQuotes
        ?.slice(0, modal ? extraQuotes.length : cutoff)
        .map(
          (
            { quote, explanation, opinion_url, quote_id, quote_vote, type },
            index
          ) => (
            <DeBriefCaseContextItem
              key={`quote-context-${index}-${measure}-${delta}`}
              quote={quote ?? ""}
              explanation={explanation ?? ""}
              opinion_url={opinion_url ?? ""}
              quote_id={quote_id}
              quote_vote={quote_vote}
              quote_type={type}
            />
          )
        )}
    </>
  );
};
