import * as api from "../../../services/api";

import {
  DeBriefCaseContextCard,
  DeBriefCaseContextCardBoxLoader,
} from "./DeBriefCaseContextCard";
import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import {
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";

import { OPINION_COUNT_LIMIT } from "../../../services/models";
import { useLowSampleThreshold } from "../../../services/hooks";

export const DeBriefCaseContextCards = ({
  type,
  judge,
  id,
  versions,
  versionIndex,
  subjects = [],
}) => {
  const version = versions[versionIndex];
  const LowSampleThreshold = useLowSampleThreshold();

  const { data } = useSuspenseQuery({
    queryKey: ["context_cards_order", id, version, type],
    queryFn: async ({ signal }) => {
      return await api.post("/quote/context_card_order", {
        body: JSON.stringify({
          measures: version[`${type}_delta`],
        }),
        signal,
      });
    },
    staleTime: 12000,
  });

  const isJudge = useMemo(() => judge?.id !== -1, [judge?.id]);

  const shouldHideContextCards = useMemo(() => {
    if (isJudge) {
      return judge.judge_opinions_count < OPINION_COUNT_LIMIT;
    } else {
      return (
        judge.court.court_opinions_count <
        judge.court.judges_count * LowSampleThreshold
      );
    }
  }, [isJudge, judge, LowSampleThreshold]);

  const datum = useMemo(() => {
    const options = {
      brief_id: id,
      brief_version_id: version?.brief_version_id,
      measure_type: type,
      subject_filter: subjects?.map((subject) => subject.id) || [],
      measures: version[`${type}_delta`],
    };

    if (isJudge) {
      options.judge_id = judge.id;
    } else {
      options.court_id = judge.court.id;
    }

    return options;
  }, [id, isJudge, judge.court.id, judge.id, subjects, type, version]);

  return (
    <Stack gap={4}>
      {shouldHideContextCards ? (
        <Typography variant="body1">
          {`The sample size for this ${
            isJudge ? "judge" : "court"
          } is too limited to produce Context Cards. To request an increase in sample size, please contact Rhetoric with your request at `}
          <a href="mailto:support@userhetoric.com">support@userhetoric.com</a>.
        </Typography>
      ) : (
        data?.map((item, index) => (
          <ContextCard
            key={`${item.id}-${index}`}
            datum={datum}
            id={id}
            version={version}
            {...item}
          />
        ))
      )}
    </Stack>
  );
};

function ContextCard({ datum, id, version, score, sub_cat }) {
  const queryClient = useQueryClient();
  const type = useMemo(() => (datum?.court_id ? "courts" : "judges"), [datum]);
  const queryKey = useMemo(
    () => ["context_cards_order", id, version, score, sub_cat, type],
    [id, version, score, sub_cat, type]
  );

  const { data, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      return await api.post(`/v2/${type}/quotes_for_context_cards`, {
        body: JSON.stringify({
          ...datum,
          measures: { [sub_cat]: score },
        }),
        signal,
      });
    },
    retry: 5,
  });

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({
        queryKey,
      });
    };
  }, [queryClient, queryKey]);

  if (isFetching) return <DeBriefCaseContextCardBoxLoader />;

  return (
    data && (
      <DeBriefCaseContextCard
        brief_id={id}
        brief_version_id={version.brief_version_id}
        {...data[0]}
      />
    )
  );
}
