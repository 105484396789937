import { Button, Divider, FormHelperText, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import { TextField } from "../../components";
import { authTemp } from "../../../services/store";
import { useAtomValue } from "jotai";
import { useAuth } from "../../../services/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  email: "",
  code: "",
  password: "",
  password_confirm: "",
};

export const ResetPasswordForm = () => {
  const user = useAtomValue(authTemp);
  const [genericError, setGenericError] = useState("");
  const navigate = useNavigate();
  const { handleForgotPasswordReset, handleCompleteNewPassword, handleLogout } =
    useAuth();
  const {
    watch,
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    setGenericError("");
    try {
      if (user?.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        await handleCompleteNewPassword(data.password);
        await handleLogout();
        navigate("/login");
      } else {
        await handleForgotPasswordReset(data.email, data.password, data.code);
        await handleLogout();
        navigate("/login");
      }
    } catch (error) {
      setGenericError(error.message);
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        {user?.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE" && (
          <>
            <TextField
              label="Email"
              name="email"
              rules={{ required: "A valid email is required." }}
              helperText={errors?.email?.message}
              control={control}
            />
            <TextField
              label="Code"
              helperText={errors?.code?.message}
              control={control}
              name="code"
              rules={{ required: "A code is required." }}
            />
          </>
        )}
        <Divider />
        <TextField
          label="Password"
          type="password"
          helperText={errors?.password?.message}
          name="password"
          control={control}
          rules={{ required: "A password is required." }}
        />
        <TextField
          label="Confirm Password"
          type="password"
          name="password_confirm"
          helperText={errors?.password_confirm?.message}
          control={control}
          rules={{
            required: "Please confirm your password.",
            validate: (value) =>
              watch("password") !== value ? "Your passwords do no match" : true,
          }}
        />
        <Button
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          size="large"
        >
          Set new password
        </Button>
        {genericError && <FormHelperText error>{genericError}</FormHelperText>}
      </Stack>
    </form>
  );
};
