import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import { grey } from "@mui/material/colors";

export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#9C27B0",
      light: "#f8e8f8",
    },
    secondary: {
      main: "#FEC722",
    },
    tertiary: {
      main: "#008bd7",
      contrastText: "#ffffff",
      hover: "#d4a227",
    },
    warning: {
      main: "#FEC722",
    },
    simple: {
      main: grey[400],
      contrastText: "#ffffff",
    },
    success: {
      main: "#7CB342",
      contrastText: "#ffffff",
    },
    error: {
      main: "#E65100",
    },
    text: {
      main: grey[700],
      light: grey[500],
      white: "#ffffff",
    },
    grey: {
      100: "#fafafa",
    },
  },
  typography: {
    fontFamily: '"Nunito", "Helvetica", sans-serif',
    fontSize: 16,
    display: {
      fontSize: 44,
      fontWeight: 800,
    },
    h1: {
      fontSize: 32,
      fontWeight: 600,
    },
    h2: {
      fontSize: 28,
      fontWeight: 600,
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
    },
    h6: {
      fontSize: 16,
      fontWeight: 400,
    },
  },
});

export default responsiveFontSizes(lightTheme);
