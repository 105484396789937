import Airtable from "airtable";

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API }).base(
  "apps1risKDD9nat9A"
);

export const createDemoBooking = (record) => {
  base("Demo Bookings").create([record]);
};

export const createUserLogin = (record) => {
  base("User Logins").create([record]);
};

export const createUserRegistration = (record) => {
  base("User Registration").create([record]);
};
