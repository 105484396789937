import * as api from "../../../services/api";
import {
  Autocomplete,
  CircularProgress,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  listCategoriesOptions,
  listSubjectsOptions,
} from "../../../services/queries";

import { QuoteVote } from "../../components";
import { Suspense } from "react";
import { memo, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import InfoIcon from "@mui/icons-material/Info";
import { OPINION_COUNT_LIMIT } from "../../../services/models";

const JudgeCourtQuotes = memo(({ id, type = "judges", isAggregate }) => {
  const [subject, setSubject] = useState([]);
  const [category, setCategory] = useState([]);
  const [judge, setJudge] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(15);

  const { data } = useSuspenseQuery({
    queryKey: [id, type, page, subject, category, judge],
    queryFn: async () => {
      const params = {};
      if (page !== 0) {
        params.page = page;
      }

      if (subject.length !== 0) {
        params.subject = subject?.map((item) => item.id).join(",");
      }

      if (category.length !== 0) {
        params.category = category?.map((item) => item.id).join(",");
      }

      if (judge.length !== 0) {
        params.judge_id = judge?.map((item) => item.id).join(",");
      }

      return await api.get(
        `/${type}/${id}/quotes${
          params.length !== 0
            ? `?${decodeURIComponent(new URLSearchParams(params).toString())}`
            : ""
        }`
      );
    },
  });

  const { data: subjects } = useSuspenseQuery(
    listSubjectsOptions(type === "judges" ? "judge" : "court", id)
  );
  const { data: categories } = useSuspenseQuery(
    listCategoriesOptions(type === "judges" ? "judge" : "court", id)
  );

  let quotes = [];
  let currentPage = 0;
  let totalPages = 0;

  if (data) {
    currentPage = data.current_page;
    totalPages = data.total_pages;
    quotes = data.quotes;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { data: judges } = useSuspenseQuery({
    queryKey: ["judges-names", "courts", id],
    queryFn: async () => await api.get(`/judges/names?court_id=${id}`),
  });

  const handleSetSubject = (event, value) => {
    setSubject(value);
    setPage(0);
  };

  const handleSetCategory = (event, value) => {
    setCategory(value);
    setPage(0);
  };

  const handleSetJudge = (event, value) => {
    setJudge(value);
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Stack
        sx={{
          alignItems: "center",
          padding: 2,
          gap: 20,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Quotes</Typography>
        <Stack sx={{ flexDirection: "row", flex: 1, gap: 5 }}>
          {!!judges.length && type === "courts" && !isAggregate ? (
            <Autocomplete
              loading={judges?.length === 0}
              multiple
              filterSelectedOptions
              size="small"
              sx={{ flex: 1 }}
              onChange={handleSetJudge}
              autoHighlight
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.label}
              options={
                judges.map((judge) => ({
                  label: judge.name,
                  id: judge.id,
                  judge_opinions_count: judge.judge_opinions_count,
                })) || []
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Judges" label="Judge" />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  {option.label}
                  {option.judge_opinions_count < OPINION_COUNT_LIMIT ? (
                    <Tooltip
                      sx={{ marginLeft: 1, marginTop: -0.5 }}
                      title="Limited Sample"
                    >
                      <IconButton size="small">
                        <InfoIcon
                          color="primary"
                          sx={{ width: 15, height: 15 }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </li>
              )}
            />
          ) : null}

          <Autocomplete
            loading={subjects?.length === 0}
            multiple
            filterSelectedOptions
            size="small"
            sx={{ flex: 1 }}
            onChange={handleSetSubject}
            autoHighlight
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.label}
            options={
              subjects?.map((subject) => ({
                label: subject.subject_area,
                id: subject.id,
              })) || []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                cy-data="quotes-filter-subject"
                placeholder="Subjects"
                label="Subject"
              />
            )}
          />
          <Autocomplete
            loading={categories?.length === 0}
            multiple
            filterSelectedOptions
            size="small"
            sx={{ flex: 1 }}
            onChange={handleSetCategory}
            autoHighlight
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.label}
            options={
              categories?.map((category) => ({
                label: category.subject_area,
                id: category.id,
              })) || []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                cy-data="quotes-filter-category"
                placeholder="Subcategories"
                label="Subcategory"
              />
            )}
          />
        </Stack>
      </Stack>
      {
        <Suspense
          fallback={
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                minHeight: 200,
              }}
            >
              <CircularProgress color="primary" size="2rem" />
            </Stack>
          }
        >
          {quotes && quotes?.length ? (
            <Table>
              <TableHead sx={{ backgroundColor: "grey.100" }}>
                <TableRow>
                  <TableCell>Vote</TableCell>
                  {!isAggregate ? <TableCell>Judge</TableCell> : null}
                  <TableCell>Subject</TableCell>
                  <TableCell>Subcategory</TableCell>
                  <TableCell>Quote</TableCell>
                  <TableCell>Explanation</TableCell>
                  <TableCell>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quotes?.map((quote, index) => (
                  <TableRow key={`quote-${quote.id}`}>
                    <TableCell>
                      <QuoteVote
                        id={quote.id}
                        vote={quote.quote_vote}
                        type={quote.type}
                      />
                    </TableCell>
                    {!isAggregate ? (
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ maxWidth: 150 }}
                      >
                        <Typography variant="caption">
                          {quote.display_name}
                        </Typography>
                      </TableCell>
                    ) : null}
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="caption"
                        cy-data="quote-subject-cell"
                      >
                        {quote.subject_area}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="caption"
                        cy-data="quote-category-cell"
                      >
                        {quote.category}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ maxWidth: 300 }}
                    >
                      <Typography variant="caption" cy-data="quote-text-cell">
                        {quote.quote}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ maxWidth: 200 }}
                    >
                      <Typography variant="caption">
                        {quote?.explanation}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link
                        href={quote.opinion_url}
                        target="_blank"
                        variant="caption"
                      >
                        Open Citation
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {totalPages > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPage={rowsPerPage}
                      page={currentPage}
                      onPageChange={handleChangePage}
                      count={totalPages * rowsPerPage}
                      rowsPerPageOptions={[15]}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          ) : (
            <Stack
              sx={{
                borderTopWidth: 1,
                borderTopStyle: "solid",
                borderTopColor: "grey.200",
                padding: 5,
              }}
            >
              <Typography
                variant="body2"
                color="grey.800"
                sx={{ textAlign: "center" }}
              >
                No Quotes Found
              </Typography>
            </Stack>
          )}
        </Suspense>
      }
    </TableContainer>
  );
});

export { JudgeCourtQuotes };
