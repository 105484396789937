import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { makePrimaryCompare, removeItemAtIndex } from "../../../services/store";
import { useCallback, useMemo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { DoctrinaCardAside } from "../../components";
import { compareDoctrinaState } from "../../../services/store";
import { hotjar } from "react-hotjar";
import { useAtom } from "jotai";

export const DoctrinaCompareCard = ({
  active,
  activeTab,
  doctrina,
  primaryDoctrina,
}) => {
  const { id } = doctrina;
  const [items, updateItems] = useAtom(compareDoctrinaState);
  const index = items.findIndex((item) => item === id);
  const theme = useTheme();

  const donutData = useMemo(() => {
    let result = {};
    switch (activeTab) {
      case 0:
        result = active
          ? doctrina.jurisprudence
          : Object.keys(doctrina.jurisprudence || {}).reduce((acc, key) => {
              acc[key] =
                doctrina.jurisprudence[key] -
                (primaryDoctrina.jurisprudence[key] || 0);
              return acc;
            }, {});
        break;
      case 1:
        result = active
          ? doctrina.tone
          : Object.keys(doctrina.tone || {}).reduce((acc, key) => {
              acc[key] = doctrina.tone[key] - (primaryDoctrina.tone[key] || 0);
              return acc;
            }, {});
        break;
      case 2:
        result = active
          ? doctrina.sentiment
          : Object.keys(doctrina.sentiment || {}).reduce((acc, key) => {
              acc[key] =
                doctrina.sentiment[key] - (primaryDoctrina.sentiment[key] || 0);
              return acc;
            }, {});
        break;
      default:
        return {};
    }
    return result;
  }, [active, activeTab, doctrina, primaryDoctrina]);

  const deleteItem = useCallback(() => {
    const updated = removeItemAtIndex(items, index);
    updateItems(updated);
  }, [items, index, updateItems]);

  const makePrimary = useCallback(() => {
    const updated = makePrimaryCompare(items, id);
    updateItems(updated);
    hotjar.event("compare-courts-change-primary");
  }, [id, items, updateItems]);

  return (
    <Paper
      elevation={1}
      sx={{
        height: "100%",
        border: active ? `3px solid ${theme.palette.primary.main}` : "none",
      }}
    >
      <Stack
        backgroundColor="white"
        color={active ? "white" : null}
        position="relative"
        height="100%"
      >
        <Box position="absolute" top={3} right={3}>
          <IconButton onClick={deleteItem}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box paddingTop={2} flex={1}>
          {active ? (
            <DoctrinaCardAside
              simple
              compact
              active={active}
              filled={active ? false : true}
              showPolarity={active ? false : true}
              donutData={donutData}
              {...doctrina}
            />
          ) : (
            <DoctrinaCardAside
              simple
              compact
              filled
              showPolarity
              active={active}
              donutData={donutData}
              {...{
                ...doctrina,
                jurisprudence: {
                  textualist:
                    doctrina.jurisprudence.textualist -
                    primaryDoctrina.jurisprudence.textualist,
                  traditionalist:
                    doctrina.jurisprudence.traditionalist -
                    primaryDoctrina.jurisprudence.traditionalist,
                  precedent:
                    doctrina.jurisprudence.precedent -
                    primaryDoctrina.jurisprudence.precedent,
                  policy:
                    doctrina.jurisprudence.policy -
                    primaryDoctrina.jurisprudence.policy,
                  purposivist:
                    doctrina.jurisprudence.purposivist -
                    primaryDoctrina.jurisprudence.purposivist,
                  originalist:
                    doctrina.jurisprudence.originalist -
                    primaryDoctrina.jurisprudence.originalist,
                },
                political_affiliations: {
                  republican:
                    doctrina.political_affiliations.republican -
                    primaryDoctrina.political_affiliations.republican,
                  democrat:
                    doctrina.political_affiliations.democrat -
                    primaryDoctrina.political_affiliations.democrat,
                  independent:
                    doctrina.political_affiliations.independent -
                    primaryDoctrina.political_affiliations.independent,
                  other:
                    doctrina.political_affiliations.other -
                    primaryDoctrina.political_affiliations.other,
                },
                sentiment: {
                  positive:
                    doctrina.sentiment.positive -
                    primaryDoctrina.sentiment.positive,
                  negative:
                    doctrina.sentiment.negative -
                    primaryDoctrina.sentiment.negative,
                  openness:
                    doctrina.sentiment.openness -
                    primaryDoctrina.sentiment.openness,
                  obstinance:
                    doctrina.sentiment.obstinance -
                    primaryDoctrina.sentiment.obstinance,
                  empathy:
                    doctrina.sentiment.empathy -
                    primaryDoctrina.sentiment.empathy,
                  detachment:
                    doctrina.sentiment.detachment -
                    primaryDoctrina.sentiment.detachment,
                },
                tone: {
                  agreeableness:
                    doctrina.tone.agreeableness -
                    primaryDoctrina.tone.agreeableness,
                  antagonistic:
                    doctrina.tone.antagonistic -
                    primaryDoctrina.tone.antagonistic,
                  formal: doctrina.tone.formal - primaryDoctrina.tone.formal,
                  informal:
                    doctrina.tone.informal - primaryDoctrina.tone.informal,
                  eccentricity:
                    doctrina.tone.eccentricity -
                    primaryDoctrina.tone.eccentricity,
                  stoicism:
                    doctrina.tone.stoicism - primaryDoctrina.tone.stoicism,
                },
              }}
            />
          )}
        </Box>
        <Stack justifyContent="center" alignItems="center">
          {active ? (
            <Typography
              backgroundColor="primary.main"
              paddingY={1}
              width="100%"
              sx={{
                textAlign: "center",
              }}
            >
              Primary
            </Typography>
          ) : (
            <Button
              onClick={makePrimary}
              variant="contained"
              fullWidth
              data-cy="make-primary-btn-doctrina"
            >
              Make Primary
            </Button>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
