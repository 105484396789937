import * as api from "../../../services/api";
import {
  Autocomplete,
  FormGroup,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Stack } from "@mui/system";
import { uploadDeBriefState } from "../../../services/store";
import { useAtom } from "jotai";
import { useCallback } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { OPINION_COUNT_LIMIT } from "../../../services/models";

export const SelectJudge = ({ disabled }) => {
  const [brief, setBrief] = useAtom(uploadDeBriefState);
  const { data: judges } = useSuspenseQuery({
    queryKey: ["judges-names", "courts", brief.court],
    queryFn: async () => await api.get(`/judges/names?court_id=${brief.court}`),
  });

  const handleChange = useCallback(
    (event, value) =>
      setBrief((current) => ({
        ...current,
        judge: value?.id || "",
        judgeName: value?.label || "",
      })),
    [setBrief]
  );

  return (
    <Stack spacing={3}>
      <form>
        <FormGroup>
          <Autocomplete
            onChange={handleChange}
            options={judges.map((judge) => ({
              label: judge.name,
              id: judge.id,
              judge_opinions_count: judge.judge_opinions_count,
            }))}
            value={brief.judgeName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Judge"
                label="Choose a judge"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                {option.label}
                {option.judge_opinions_count < OPINION_COUNT_LIMIT ? (
                  <Tooltip
                    sx={{ marginLeft: 1, marginTop: -0.5 }}
                    title="Limited Sample"
                  >
                    <IconButton size="small">
                      <InfoIcon
                        color="primary"
                        sx={{ width: 15, height: 15 }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </li>
            )}
          />
        </FormGroup>
      </form>
    </Stack>
  );
};
